import React, { FC, useRef, useState, useEffect, useMemo } from 'react'

import qs from 'qs'

import Flicking, { ViewportSlot } from "@egjs/react-flicking";

import { AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";

import AnimateHeight from 'react-animate-height'

import { size } from 'lodash'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { shopStateAtom } from '@state/shop'
import { mallStateAtom } from '@state/mall'

import { callBackStateAtom } from '@state/callBack'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import { shopOpenPageStepStateAtom } from '@state/shopOpenPageStep'

import { productStateAtom } from '@state/product'
import { productSortStateAtom } from '@state/productSort'

import { basketStateAtom } from '@state/basket'

import { categoriesStateAtom } from '@state/categories'
import { categoryStateAtom } from '@state/category'

import { companyStateAtom } from '@state/company'
import { aiStateAtom } from '@state/ai'

import { bookmarksStateAtom } from '@state/bookmarks'

import { useCheckingProductBookmarks } from '@hooks/useCheckingProductBookmarks'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';

import TextField from '@mui/material/TextField'

import TuneIcon from '@mui/icons-material/Tune';

import CircularProgress from '@mui/material/CircularProgress';

import Divider from '@mui/material/Divider';

import { useForm, Controller } from 'react-hook-form'

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import CardMedia from '@mui/material/CardMedia';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import useMediaQuery from '@mui/material/useMediaQuery';

import useMeasure from '@hooks/useMeasure'

import useMobileDetect from '@hooks/useMobileDetect'

import Loading from '@components/Loading'

import api from '@services/api'

import { replaceImageFormat } from '@utils/replaceImageFormat'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import Draggable from '@components/Draggable'

import {getWeekDay, parseTimeToDate,convertTimeFormat} from '@utils/getWeekDay'

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
})

const  { REACT_APP_FAKE_AWS, REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

declare var window: Window & typeof globalThis;


interface ChildProps {
  child: any;
}

const ChildItem: React.FC<ChildProps> = ({ child }) => {

  const [shopOpenPageStep, setShopOpenPageStep] = useRecoilState<string>(shopOpenPageStepStateAtom) // categories, products, product
  const [category, setCategory] = useRecoilState<any>(categoryStateAtom)

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {

    /* console.log(`child eee`)
    console.log(child) */

    if(child.childrens.length === 0) {

      console.log('products');
      setShopOpenPageStep('products');
      setCategory(child);
    }
    
    setOpen(!open);
  };

  return (
    <Box /* sx={{border: '1px solid #e0e0e0', borderRadius: 3, m: 0, mt: 2}} */>

      <ListItem button onClick={handleClick} sx={{ pl: 1, pr: 1 }}>
        <ListItemText primary={child?.name} />
        {child?.childrens?.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {child?.childrens?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding  sx={{ pl: 4 }}>
            {child?.childrens.map((child:any) => (
              <ChildItem key={child?.id} child={child} />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

interface ParentProps {
  parent: any;
}

const ParentItem: React.FC<ParentProps> = ({ parent }) => {

  const [shopOpenPageStep, setShopOpenPageStep] = useRecoilState<string>(shopOpenPageStepStateAtom) // categories, products, product
  const [category, setCategory] = useRecoilState<any>(categoryStateAtom)

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {

    if(parent.childrens.length === 0) {

      setShopOpenPageStep('products');
      setCategory(parent);
    }
    
    setOpen(!open);
  };

  return (
    <Box sx={{border: '1px solid #e0e0e0', borderRadius: 2, m: 0, mt: 2}}>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={parent.name}  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} />
        {parent.childrens.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
      {parent.childrens.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl:1, whiteSpace: 'nowrap' }}>
            {parent.childrens.map((child:any) => (
              <ChildItem key={child.id} child={child} />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

interface ListProps {
  data: any[];
  products_types: string
}

const HierarchicalList: React.FC<ListProps> = ({ data, products_types }) => {
  return (

    <List
      sx={{ 
        width: '100%',  
        bgcolor: 'background.paper',
        overflow: 'hidden',
        mt: 0,
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {display: 'none',},
        '&-ms-overflow-style:': {display: 'none',},
      }}
      component="nav"
    >

    {(products_types === 'mens_girls_childs' || products_types === 'mens_girls' || products_types === 'girls_childs' ) && data.map((parent) => (
      <ParentItem key={parent.id} parent={parent} />
    ))}
    

    {products_types === 'mens' && data.filter((parent) => parent.id === 3 /* .name === 'Мужская' */).map((parent) => (
      <>
        {parent.childrens.map((child:any) => (
          <ParentItem key={child.id} parent={child} />
        ))}
      </>
    ))}

    {products_types === 'girls' && data.filter((parent) => parent.id === 4 /* .name === 'Женская' */).map((parent) => (
      <>
        {parent.childrens.map((child:any) => (
          <ParentItem key={child.id} parent={child} />
        ))}
      </>
    ))}

    {products_types === 'childs' && data.filter((parent) => parent.id === 5 /* .name === 'Детская' */).map((parent) => (
      <>
        {parent.childrens.map((child:any) => (
          <ParentItem key={child.id} parent={child} />
        ))}
      </>
    ))}
      
    </List>
  );
};

const ProductItem: FC<any> = ({ product }) => {

  const setOpenProductDetails = useSetRecoilState<any>(productStateAtom)
    
  const [basket, setBasket] = useRecoilState<any[]>(basketStateAtom)

  const shop = useRecoilValue(shopStateAtom)

  const handleCheckedBasket = () => {

    for(const item of basket) {

      if(item?.id === product?.id) return true;
    }
    return false
  };
  
  const handleAddBasket = () => {

    setBasket([...basket, {...product, shop}]);
  };

  const handleRemoveBasket = () => {

    setBasket(basket.filter((item) => item?.id !== product?.id));
  };

  const firstImage = product?.files?.find((item: any) => item?.mime.includes("image"));

  return (
    <Grid xs={6}>
      <Box sx={{m: 0, maxWidth: 300,}}>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          height="100%"
          width="100%"
        >
         {/*  <Box
            sx={{ 
              backgroundImage:`url(${product?.photo?.url})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: 280,
              width: '100%',
              borderRadius: 5,
            }}
            onClick={()=>{
              setOpenProductDetails(product)
            }}
          /> */}
              <Box
                sx={{ 
                  backgroundImage:`url(${firstImage?.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: 'center',
                  height: 280,
                  width: '100%',
                  borderRadius: 5,
                }}
                onClick={()=>{
                  setOpenProductDetails(product)
                }}
              /> 

        </Box>

        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
          sx={{
            m: 1,
            height: '100%',
            width: '100%',
          }}
        >

          <Box sx={{width: '100%'}}>

            <Typography variant="h6" align="left"  component="span"  dangerouslySetInnerHTML={{__html: product?.name}} sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            '& p': {margin: 0,padding: 0,},}}
            onClick={()=>{

              setOpenProductDetails(product)
              
            }}/>

            <Typography variant="subtitle2" align="left"  component="span"  sx={{ /* pl: 2, */'& p': {margin: 0,padding: 0,}, transform: 'scale(1.2)'}}
            onClick={()=>{
              setOpenProductDetails(product)
            }}>{product?.price}₽</Typography>

            <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: product?.description}} sx={{
              mt: 1,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              '& p': {margin: 0,padding: 0,},}}/>

          </Box>

        </Stack>

        <Button sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }} onClick={handleCheckedBasket() ? handleRemoveBasket : handleAddBasket}>

          { handleCheckedBasket() ? "Удалить" : "В корзину" }

        </Button>

      </Box>
    </Grid>
  )
}

const Product: FC = () => {

  const { windowSize } = useMeasure()
  
  const [plugins, setPlugins] = useState<any>([]);

  useEffect(() => {
    setPlugins( [ new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false, }) ]);
  }, []);
  
  const width = 360 > (windowSize.width - 60) ? ( windowSize.width - 60 ) : 360 //360;

  const height = 540;

  const flickingRef = useRef<Flicking>(null);

  const mobile = useMobileDetect()

  const refContainer = useRef<any>(null)
  const refShop = useRef<any>(null)

  const [openProductDetails, setOpenProductDetails] = useRecoilState<any>(productStateAtom)

  const [shopOpenPageStep, setShopOpenPageStep] = useRecoilState<string>(shopOpenPageStepStateAtom) // categories, products, product

  const [shop, setShop] = useRecoilState<any>(shopStateAtom)
  
  const [products_types, setProducts_types] = useState<string>('mens_girls_childs')

  const [options, setOptions] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  
  const [basket, setBasket] = useRecoilState<any[]>(basketStateAtom)

  const handleCheckedBasket = (product:any) => {

    for(const item of basket) {

      if(item?.id === product?.id) return true;
    }
    return false
  };
  
  const handleAddBasket = (product:any) => {

    setBasket([...basket, product]);
  };

  const handleRemoveBasket = (product:any) => {

    setBasket(basket.filter((item) => item?.id !== product?.id));
  };

  const [morePhotos, setMorePhotos] = useState<boolean>(false)
  
  const [bookmarks, setBookmarks] = useRecoilState<any>(bookmarksStateAtom)
  
  const ai = useRecoilValue<any>(aiStateAtom)
  const company = useRecoilValue<any>(companyStateAtom)

  const setCallBack = useSetRecoilState<boolean>(callBackStateAtom)  

  const [shareLot, setShareLot] = useState<boolean>(false)

  const [qrcode, setQrcode] = useState<boolean>(false)

  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)
  const setCallBackAttachData = useSetRecoilState<any>(callBackAttachDataStateAtom)


  const categories = useRecoilValue<any>(categoriesStateAtom)

  
  const [category, setCategory] = useRecoilState<any>(categoryStateAtom)

  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([])
  const [product, setProduct] = useState<any>()


  const searchFormTextFieldRef = useRef<any>(null)
  const [search, setSearch] = useState('')


  const [productSort, setProductSort] = useRecoilState<any>(productSortStateAtom)


  const [data, setData] = useState<any>(null)
  const [status, setStatus] = useState<any>(null)

  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  })

  useEffect(() => {}, [bookmarks])

  useEffect(() => {

    if(!shop) return;
    const random = Math.random().toString(36)

    window.history.pushState({ product: random }, "shop", "?shop="+random);

  }, [shop])
  
  const onClose = () => {

    setShopOpenPageStep('categories')

    setLoading(false)
    setMorePhotos(false)
    setOptions(false)
    setShop(null)
    setCallBackButton(false)
  }

  useEffect(() => {

    setLoadingProducts(true);

    const query = qs.stringify({
      randomSort: false,
      filters: {
        shop: {
          id: shop?.id
        },
        category: {
          id: category?.id
        },
        ...(search !== null && search?.length > 0 && {
          '$or': [
            {
              name: {
                $containsi: search
              }
            },
            {
              description: {
                $containsi: search
              }
            },
            {
              sku: {
                $containsi: search
              }
            },
          ],
        }),
      },
      populate: {
        files: {
          populate: '*'
        },
        photo: {
          fields: ['url']
        },
        photos: {
          fields: ['url']
        },
      },
      fields: ['name', 'description', 'price', 'stock', 'sku'],
      locale: ['ru'],
    }, {
      encodeValuesOnly: true, // prettify URL
    })

    api({ collection: 'products', query }).then((data) => {
      
      setLoadingProducts(false);

      if(data?.data) setProducts(data?.data);

    }).catch((error:any) => {

      setLoadingProducts(false);

      console.log(error)
    })
  
  }, [search])

  useEffect(() => {
    
    if(size(shop)>0){

      setLoading(true)

      const query = qs.stringify({
        randomSort: false,
        populate: {

          site: true,
          phones: true,
          social_networks: true,
          work_times: {
            populate: {
              work_times_day_of_week: true
            }
          },
          files: {
            populate: '*'
          },
          photo: {
            fields: ['url']
          },
          
          photos: {
            fields: ['url']
          },
        },
        locale: ['ru'],
      }, {
        encodeValuesOnly: true, // prettify URL
      })

      api({ id: shop.id, collection: 'shops', query }).then((data) => {

        if(data){

          setCallBackAttachData(data.data)
          setData(data.data)
          setStatus(data.status)
          setCallBackButton(true)

          console.log(`data?.data?.products_types`)
          console.log(data?.data?.products_types)

          setProducts_types(data?.data?.products_types)
        }
  
        setLoading(false)
  
      }).catch((error:any) => {

        setLoading(false)
      })
    }
  }, [shop])

  const ref = useRef<any>(null)

  useEffect(() => {

    if(!mobile){  

      setTimeout(() => {
        if(ref.current) {
          
          ref.current.scrollTo({ top:  320, behavior: 'smooth' })
        };
      }, 250)
    }
  }, [morePhotos, options])

  const [open, setOpen] = useState(true);

  useEffect(() => {

    if(category&&shop){

      setLoadingProducts(true);

      const query = qs.stringify({
        randomSort: false,
        filters: {
          shop: {
            id: shop?.id
          },
          category: {
            id: category?.id
          }
        },
        populate: {
          files: {
            populate: '*'
          },
          photo: {
            fields: ['url']
          },
          photos: {
            fields: ['url']
          },
        },
        fields: ['name', 'description', 'price', 'stock', 'sku'],
        locale: ['ru'],
        sort: [productSort.value.sort],
      }, {
        encodeValuesOnly: true, // prettify URL
      })

      api({ collection: 'products', query }).then((data) => {

        setLoadingProducts(false);

        if(data?.data) setProducts(data?.data);
  
      }).catch((error:any) => {
        
        setLoadingProducts(false);

        console.log(error)
      })
    }
  }, [category, productSort])

  const header = () => {

    return (
      <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      spacing={1}
      
      sx={{
        p: windowSize.width < 375 ? 1 : 2,
        width: '100%',
      }}>

        <ArrowBackIosNewIcon sx={{ cursor: 'pointer', width: 48 }} onClick={() => {setShopOpenPageStep('categories');}}/>

        <Controller
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, isDirty, error },
          }) => (
            <TextField
              fullWidth
              size="small"
              margin="normal"
              variant="outlined"
              //label="Поиск"

              onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
              autoFocus={false}
              placeholder="Поиск"

              value={search}
              
              onBlur={onBlur}
              inputRef={(input) => {

                ref(input);
                if (input) searchFormTextFieldRef.current = input;
              }}
              onChange={(e:any)=>{

                setSearch(e.target.value);
              }}
              onFocus={(e:any)=>{

                setOpen(!open)
              }}
              InputProps={{
                endAdornment: search !== '' && (
                  
                  <InputAdornment position="end" sx={{ marginRight: 1 }}>
      
                    <IconButton onClick={()=>{
                      setSearch('');
                      }} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          name="name"
          control={control}
          rules={{ required: true }}
        />

        <TuneIcon sx={{ cursor: 'pointer', width: 48 }} onClick={() => { setProductSort((prevState:any) => ({ ...prevState, open: true }) )}}/>

      </Stack>
    )
  }

  const [openWorkTime, setOpenWorkTime] = useState(false);

  const workTime = (work_times_day_of_week:any) => {

    let open = false
    const day = getWeekDay()

    work_times_day_of_week?.map((item:any, index:number)=>{

      if(!item?.from || !item?.to) return;

      if(day === item?.day ){
        open = ((new Date().getTime() >= parseTimeToDate(item?.from).getTime()) && (new Date().getTime() <= parseTimeToDate(item?.to).getTime())) ? true : false 
      }
    })
      
    return open
  }


  const [ContainerFullHeight, setContainerFullHeight] = useState<boolean>(false)

  useEffect(() => {

    //@ts-ignore
    const windowHeight = window?.innerHeight ? window?.innerHeight : 0;
    const containerHeight = refContainer?.current?.clientHeight ? refContainer?.current?.clientHeight : 0;

    console.log(`windowHeight`)
    console.log(windowHeight)

    console.log(`containerHeight`)
    console.log(containerHeight)

    setContainerFullHeight(windowHeight * 0.9 > containerHeight ? true : false)

  }, [refContainer?.current?.clientHeight])

  useEffect(() => {
    console.log(`ContainerFullHeight: ${ContainerFullHeight}`)
  }, [ContainerFullHeight])
  
  return (
    <>

        <SwipeableDrawer
        anchor={'bottom'}
        open={size(shop)>0||false}
        onClose={onClose}
        onOpen={()=>{}}
        maxWidth
        sx={{
          zIndex: 1202,
          '& .MuiPaper-root': {
            "&" : {
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
            },
          },
        }}
        maxHeight={'90vh'}
        >

          <Container ref={refContainer} maxWidth="sm" sx={{ maxHeight: '90vh', overflow: 'hidden',  p: 0, pt: 2, }}>
          
            { loading ?

              <Loading/>

              :

              <>

              <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': { display: 'none', },
                '&-ms-overflow-style:': { display: 'none', },
              }}
              >

                <Box sx={{ 
                  width: '100vw',
                  maxWidth: 420,
                  overflow: 'hidden',
                }}>
                    
                  <Typography variant="h6" gutterBottom align="center" sx={{ mt: 2, width: '100%' }}>

                    {shop?.name}

                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{
                      p: 2,
                      pb: 5,
                      maxHeight:"90vh",
                      overflow: 'scroll',
                      scrollbarWidth: "none",'&::-webkit-scrollbar': {display: 'none',},'&-ms-overflow-style:': {display: 'none',},
                    }}>

                    <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      p: windowSize.width < 375 ? 0 : 2,
                      pt: 0,
                      mb: 2,
                      borderRadius: 2,
                    }}>

                      { ( data?.photos && data?.photos?.length > 0 ) && 

                        <Box sx={{ borderRadius: 3, overflowX: "hidden" }}>
                          <Flicking ref={flickingRef} plugins={plugins} circular={true}>
                              {data?.photos?.map((item:any) => (
                                  <Box
                                  className="card-panel"
                                  sx={{
                                    backgroundImage:`url(${item?.url})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    width: "100%",
                                    height: 200,
                                  }}
                                  />
                              ))}
                          </Flicking>
                        </Box>
                      }

                      <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: shop?.description}} 
                      sx={{
                        '& p': {
                          margin: 0,
                          padding: 0,
                        },
                        p: 2,
                      }}/>

                      <Box
                          height="100%"
                          width="100%"
                          p={0}
                        >

                        <Divider sx={{ mt: 0, mb: 1 }}/>

                        { data?.address && 
                          <>
                            
                            <Typography variant="body2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: data?.address}} sx={{
                            mt:-0.7,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            '& p': {margin: 0,padding: 0,},
                            }}/>
                            <Divider sx={{ mt: 1, mb: 1 }}/>
                          </>
                        }

                        { ( data?.work_times && data?.work_times?.work_times_day_of_week?.length > 0  ) &&

                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            width="100%"
                            onClick={()=>{
                              setOpenWorkTime(!openWorkTime)
                            }}>
                            <Grid container spacing={0}>
                              
                              <Grid xs={5}>
                                <Typography variant="body1" align="left"  dangerouslySetInnerHTML={{__html: 'Bремя работы:'}} sx={{color: 'black', whiteSpace: 'nowrap',}}/> 
                              </Grid>
                              <Grid xs={7}>
                                <Typography variant="body1" align="left"  sx={{color: workTime(data?.work_times?.work_times_day_of_week) ? 'green' : 'rgb(198, 0, 0)', pl: 2, mr: 2, mb: 1 }}> 
                                {workTime(data?.work_times?.work_times_day_of_week) ? <>Сейчас открыто</> : <>Сейчас закрыто</>}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Box>
                              { openWorkTime ? <ExpandLess /> : <ExpandMore />}
                            </Box>
                          </Box>
                          
                          <AnimateHeight
                          duration={500}
                          height={openWorkTime ? 'auto':0}
                          >
                            <Grid container spacing={0}>

                              {data?.work_times?.work_times_day_of_week?.map((item:any, index:number)=>{

                              if(!item?.work){ return (
                                <>
                                  <Grid xs={4}>
                                    <Typography key={index} variant="body2" align="left" sx={{color: 'black', }}>{item?.day}</Typography>
                                  </Grid>
                                  <Grid xs={8}>
                                    <Typography key={index} variant="body2" align="left" sx={{color: 'rgb(198, 0, 0)', pl: 2, }}>выходной</Typography>
                                  </Grid>
                                </>
                                )}
                                return (
                                <>
                                  <Grid xs={4}>
                                    <Typography key={index} variant="body2" align="left" sx={{color: 'black', }}>{item?.day}</Typography>
                                  </Grid>
                                  <Grid xs={8}>
                                    <Typography key={index} variant="body2" align="left" sx={{color: getWeekDay() /* 'Пятница'  */=== item?.day ?  

                                    new Date().getTime() >= parseTimeToDate(item?.from).getTime() ? ( new Date().getTime() <= parseTimeToDate(item?.to).getTime() ? 'green' : 'black' )  
                                    :'black'
                                    : 'black', pl: 2 }}>{convertTimeFormat(item?.from)} – {convertTimeFormat(item?.to)}</Typography>
                                  </Grid>
                                </>
                                )
                              })}
                            
                            </Grid>
                          </AnimateHeight>

                          <Divider sx={{ mt: 1, mb: 1 }}/>
                        </>
                        }

                        { data?.site && 
                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                              width="100%"
                            >

                              <Typography variant="body1" align="left"  dangerouslySetInnerHTML={{__html: 'Сайт:'}} sx={{color: 'black', width: 110}}/>

                              <Button onClick={()=>{window.open(`${data?.site}`, '_blank')}} sx={{ m:0, ml: 2.25, mt: -0.5, p: 0, height: '32px !important', backgroundColor: 'transparent', border: 'solid black 1px', borderRadius: 1, pl: 1, pr: 1, }}>
                                <Typography variant="body2" align="left" dangerouslySetInnerHTML={{__html: 'Перейди'}} sx={{color: 'black', }}/>
                              </Button>
                            </Box>

                            <Divider sx={{ mt: 1, mb: 1, backgroundColor: 'red' }}/>
                          </>
                        }
                        
                        { ( data?.phones && data?.phones?.length > 0  ) &&

                          <>
                            
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>

                            <Typography variant="body1" align="left"  dangerouslySetInnerHTML={{__html: 'Телефоны:'}} sx={{color: 'black', width: 150}}/>
                            
                              <Box sx={{ display: 'block', width: '100%', ml: 1, whiteSpace: 'nowrap',  overflowX: 'auto', overflowY: 'hidden', scrollbarWidth: "none",'&::-webkit-scrollbar': {display: 'none',},'&-ms-overflow-style:': {display: 'none',},
                            }}>

                              {
                                data?.phones?.map((item:any, index:number)=>{
                                  return (
                                    <Button key={index} onClick={()=>{window.open(`tel:${item?.phone}`, '_blank')}} sx={{ m:0, ml: 2, p: 0, pl:0.5, pr:0.5,  height: '32px !important', 
                                    backgroundColor: 'transparent', }}>
                                      <Typography variant="body2" align="left" dangerouslySetInnerHTML={{__html: item?.phone}} sx={{color: 'black', }}/>
                                    </Button>
                                  )
                                })
                              }
                              </Box>
                            </Box>
                            <Divider sx={{ mt: 1, mb: 1 }}/>
                          </>
                        }

                        { ( data?.social_networks && data?.social_networks?.length > 0  ) &&

                          <>

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start',  whiteSpace: 'nowrap', }}>

                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent:"space-around", width: '100%', }}>
                              {
                                data?.social_networks?.map((item:any, index:number)=>{
                                  return (
                                    <Box
                                    key={index}
                                    sx={{
                                      backgroundImage:`url(/images/Color/${item?.social}.svg)`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                      width: 24,
                                      height: 24,
                                      /* ml: 1, */
                                      cursor: 'pointer',
                                      '&:hover': {
                                        transform: 'scale(1.4)',
                                      },
                                    }}
                                    onClick={()=>{
                                      window.open(item?.url, '_blank')
                                    }}
                                    />
                                  )
                                })
                              }
                              </Box>
                            </Box>

                            <Divider sx={{ mt: 1, mb: 1 }}/>
                            
                          </>
                        }

                        <Typography variant="h6" align="left"  dangerouslySetInnerHTML={{__html: 'Каталаг'}} sx={{color: 'rgb(98, 100, 101)'}}/>

                        <HierarchicalList data={categories} products_types={products_types} />

                      </Box>

                    </Box>

                  </Box>
                </Box>

                <Draggable open={shopOpenPageStep === 'products'} setOpen={()=>{setShopOpenPageStep('categories');}}>

                  <Box 
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    overflow: 'hidden',
                    width: '100%',
                    //pt: 'env(safe-area-inset-top)',
                  }}>

                    { search.length > 0 && products.length === 0 ? 
                      
                      <>

                        {header()}

                        <Box 
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          overflow: 'hidden',
                          width: '100%',
                          height: "80vh",
                        }}>

                          <ProductionQuantityLimitsIcon fontSize='large' sx={{transform: 'scale(3)'}}/>

                          <Typography variant="h6" align="left"  component="span" sx={{mt: 5}}>

                            Ничего не найдено!

                          </Typography>

                        </Box>

                      </>
                    :
                      <>

                        {header()}

                        <Container maxWidth="sm" sx={{ mt: 0, pt:0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', }}>

                          { loadingProducts ? 

                          <Box sx={{ height: 'calc(100vh - 120px)', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>

                            <CircularProgress color="secondary" />
                             
                          </Box>
                          :
                          <>
                            { products.length === 0 && 
                              
                              <>

                                <Box 
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  overflow: 'hidden',
                                  width: '100%',
                                  height: "80vh",
                                }}>

                                  <ShoppingCartIcon fontSize='large' sx={{transform: 'scale(3)'}}/>

                                  <Typography variant="h6" align="left"  component="span" sx={{mt: 5}}>

                                  ОЙ! А тут пусто...

                                  </Typography>

                                </Box>

                              </>
                            }
                              
                            <Grid container spacing={1}
                            sx={{
                              userSelect: 'none',
                              overflow: 'auto',
                              height: 'calc(100vh - 120px)',
                              pb: 5,
                              width: '100%',
                              scrollbarWidth: "none",
                              '&::-webkit-scrollbar': { display: 'none', },
                              '&-ms-overflow-style:': { display: 'none', },
                            }}>
                              
                              {products?.map((product:any) => (<ProductItem key={product?.id} product={product} />))}

                            </Grid>

                          </>
                          }

                        </Container>

                      </>
                    }

                  </Box>
                  
                </Draggable>

                <Draggable open={size(openProductDetails)>0||false} setOpen={()=>{setOpenProductDetails(null);}}>
                                  
                  <Box 
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  sx={{
                    overflow: 'hidden',
                    width: '100%',
                    pt: 3.5,
                    //pt: 'calc(30px + env(safe-area-inset-top))',
                  }}>
                  
                    <Grid container sx={{ width: '100%' }}>
                      
                      <Grid xs={2} >
          
                        <ArrowBackIosNewIcon sx={{ cursor: 'pointer', width: 48, ml: 2 }} onClick={() => { setOpenProductDetails(null); }}/>
          
                      </Grid>
          
                      <Grid xs={9}>
                  
                        <Typography variant="h6" align="left"   component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.name}} sx={{
                        mt: -0.5,
                        ml: 0,
                        width: '100%',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        }}/>
                  
                      </Grid>
          
                    </Grid>

                    <Box
                      sx={{
                        mt: 2,
                        p: 5,
                        pt: 1,
                        pb: 1,
                        userSelect: 'none',
                        overflow: 'auto',

                        height: "80vh",
                        width: '100vw',
                        scrollbarWidth: "none",
                        '&::-webkit-scrollbar': { display: 'none', },
                        '&-ms-overflow-style:': { display: 'none', },
                      }}>

                      <Box 
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        userSelect: 'none',
                        overflow: 'auto',
                        width: '100%',
                        height, minHeight: height,
                        borderRadius: 3,
                      }}>
                  
                        <Box sx={{ borderRadius: 3, overflowX: "hidden", width, height, minHeight: height }}>
                          <Flicking ref={flickingRef} /* plugins={plugins} */ circular={ ( openProductDetails?.photos?.length === 1 && !openProductDetails?.video ) ? false : true}>
                            {/* {openProductDetails?.video &&
                              <Box
                                className="card-panel"
                                sx={{
                                  width,
                                  height,
                                }}>

                                <CardMedia
                                    component='video'
                                    image={openProductDetails?.video.url}
                                    src={openProductDetails?.video.url}
                                    autoPlay
                                    loop
                                    muted
                                />
                              </Box>
                            }
                            {openProductDetails?.photos?.map((item:any, index:number) => (
                              <Box
                                key={index}
                                className="card-panel"
                                sx={{
                                  backgroundImage:`url(${item?.url})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  width,
                                  height,
                                }}/>
                            ))} */}

                            {openProductDetails?.files?.map((item:any, index:number) => {

                              if(item?.mime.includes("image")) {
                                return (
                                  <Box
                                    key={index}
                                    className="card-panel"
                                    sx={{
                                      backgroundImage:`url(${item?.url})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      width,
                                      height,
                                    }}/>
                                )
                              }

                              if(item?.mime.includes("video")) {
                                return (
                                  <Box
                                  className="card-panel"
                                  sx={{
                                    width,
                                    height,
                                  }}>

                                  <CardMedia
                                      component='video'
                                      image={item?.url}
                                      src={item?.url}
                                      autoPlay
                                      loop
                                      muted
                                  />
                                </Box>
                                )
                              }

                              return null;

                            })}
                          </Flicking>
                        </Box>
                      </Box>
        
                      <Stack
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={1}
                      sx={{
                        m: 1,
                        mt: 2,
                        width: 'calc(100% - 16px)',
                      }}>

                        <Box sx={{width: '100%'}}>
                            
                          <Typography variant="subtitle1" align="left"  component="span"  sx={{ /* pl: 2, */'& p': {margin: 0,padding: 0,}, transform: 'scale(1.2)'}}>{openProductDetails?.price}₽</Typography>

                          <Box>
                            
                            <Typography variant="caption" align="left"  component="span"  sx={{'& p': {margin: 0,padding: 0,},}}>Арт</Typography>
                            <Typography variant="caption" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.sku}} sx={{ pl: 1, '& p': {margin: 0,padding: 0},}}/>

                          </Box>

                          <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.description}} sx={{
                          mt: 1,
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 5,
                          '& p': {margin: 0,padding: 0,},}}/>

                          <Button sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }} onClick={()=>{
                            handleCheckedBasket(openProductDetails) ? handleRemoveBasket(openProductDetails) : handleAddBasket(openProductDetails)
                          }}>
                            { handleCheckedBasket(openProductDetails) ? "Удалить" : "В корзину"}
                          </Button>

                        </Box>

                      </Stack>

                    </Box>

                  </Box>

                </Draggable>


              </Box>

              </>
            }

          </Container>

        </SwipeableDrawer>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={shareLot||false}
          autoHideDuration={2500}
          onClose={()=>{setShareLot(!shareLot)}}
          message="Ссылка скопирована"
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2,
              zIndex: 10000,
            },
            '& .MuiSnackbarContent-message ': {
              display: 'block',
              textAlign: "center",
              width: '100%',
            },
          }}
        />
        
    </>
  )
}

export default Product
