import { atom, selector } from "recoil"

export const authPageStateAtom = atom({
  key: "authPageStateAtom",
  default: 'loginOrRegister',
})

export const authPageStateSelector = selector({
  key: "authPageStateSelector",
  get: ({ get }) => {
    const authPage = get(authPageStateAtom)
    return authPage
  }
})

