import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const basketStateAtom = atom({
  key: "basketStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],

})

export const basketStateSelector = selector({
  key: "basketStateSelector",
  get: ({ get }) => {
    const basket = get(basketStateAtom)
    return basket
  }
})

