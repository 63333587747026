import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const productSortStateAtom = atom({
  key: "productSortStateAtom",
  default: {
    open: false,
    value: { type: 'default', sort: 'id:DESC', name: 'по умолчанию' },

    /* type: 'new', // new, price
    sort: 'sort-asc', // sort-asc, sort-desc */
  },
  effects_UNSTABLE: [persistAtom],
})

export const productSortStateSelector = selector({
  key: "productSortStateSelector",
  get: ({ get }) => {
    const productSort = get(productSortStateAtom)
    return productSort
  }
})

