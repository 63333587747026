import { atom, selector } from "recoil"

export const productEditStateAtom = atom({
  key: "productEditStateAtom",
  default: null,
})

export const productEditStateSelector = selector({
  key: "productEditStateSelector",
  get: ({ get }) => {
    const productEdit = get(productEditStateAtom)
    return productEdit
  }
})

