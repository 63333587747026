import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const recentlyViewedProductsStateAtom = atom({
  key: "recentlyViewedProductsStateAtom",
  default: [],
  effects_UNSTABLE: [persistAtom],
})

export const recentlyViewedProductsStateSelector = selector({
  key: "recentlyViewedProductsStateSelector",
  get: ({ get }) => {
    const recentlyViewedProducts = get(recentlyViewedProductsStateAtom)
    return recentlyViewedProducts
  }
})

