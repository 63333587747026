import { FC, useRef, useState, useEffect } from 'react';

import qs from 'qs'

import { useSetRecoilState } from 'recoil'
import { pageStateAtom } from '@state/page'

import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'

import Flicking, { ViewportSlot } from "@egjs/react-flicking";
/* import { AutoPlay } from "@egjs/flicking-plugins"; */
import "@egjs/react-flicking/dist/flicking.css";

import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

import Api from "@services/api";

import './pagination.css';

const Ads: FC<any> = () => {

  const [data, setData] = useState<any[]>([])
  
  const setPage = useSetRecoilState(pageStateAtom)

  //const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false, }), new Pagination({ type: 'bullet' })];

  const [plugins, setPlugins] = useState<any>([]);

  useEffect(() => {
      setPlugins( [ new Pagination({ type: 'bullet' }) ]);
  }, []);

  const width = '200px'
  const height = '280px'

  const flickingRef = useRef<Flicking>(null);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const handleNext = () => {
    if (flickingRef.current) {
      const flicking = flickingRef.current;
      const isLast = flicking.index === flicking.panelCount - 1;
      if(isLast) return setPage('map');
    }
    return flickingRef?.current?.next();
  };

  const handleChanged = (e: any) => {
    if (flickingRef.current) {
      const flicking = flickingRef.current;
      const isLast = flicking.index === flicking.panelCount - 1;
      setIsLastSlide(isLast);
    }
  };

  useEffect(() => {
    if (flickingRef.current) {
      const flicking = flickingRef.current;
      flicking.on('changed', handleChanged);
    }

    return () => {
      flickingRef?.current?.off('changed', handleChanged);
    };
  }, []);

  useEffect(() => {
  
    const query = qs.stringify({
      randomSort: false,
      populate: {
        image: {
          fields: ["url"],
        },
      },
      pagination: {
        pageSize: 15,
        page: 1,
      },
      sort: "createdAt:ASC",
      locale: ['ru'],
    }, {
      encodeValuesOnly: true,
    })

    Api({ collection: 'welcome-apps', query }).then((data:any) => {

      if(data?.data) return setData([...data?.data]);
      
    }).catch((error:any) => {

      console.log(error)
    })
  }, [])


  return (
    <Container maxWidth="sm">
      <Box sx={{ borderRadius: 3, overflow: "hidden", position: "relative", top: '50vh', transform: 'translateY(-50%)' }}>
        <Flicking ref={flickingRef} plugins={plugins} circular={false} moveType={["strict", { count: 1 }]}>
            {data?.map((item:any, index:number) => (
              <Box
              key={index}
              sx={{
                width: '100vw',
                height: 500,
              }}>
                <Box
                className="card-panel"
                sx={{
                  backgroundImage:`url(${item?.image?.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width,
                  height,
                  left: '50%',
                  top: 0,
                  transform: 'translateX(50%)',
                }}
                onClick={handleNext}
                />
                <Typography sx={{ m: 2.5, textAlign: "center" ,  typography: {  xs: 'h6', sm: 'h6', md: 'h6', lg: 'h6' }}}>
                  {item?.name}
                </Typography>
                <Typography sx={{m: 2, textAlign: "center" ,  typography: {  xs: 'body2', sm: 'body2', md: 'body2', lg: 'body2' }}}>
                  {item?.description}
                </Typography>
              </Box>
            ))}
          <ViewportSlot>
            <div className="flicking-pagination"></div>
          </ViewportSlot>
        </Flicking>
      </Box>
      <Box sx={{ position: "absolute", bottom: 25, left: '50vw', transform: 'translateX(-50%)', }}>
          <Button variant="contained" onClick={handleNext}>{isLastSlide ? <div>Запустить</div> : <div>Далее</div>}</Button>
      </Box>
  </Container>);
};


export default Ads