
import { FC, MouseEvent, useEffect, useState } from 'react'

import axios from 'axios'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { callBackStateAtom } from '@state/callBack'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import { jwtStateAtom } from '@state/jwt'
import { authPhoneStateAtom } from '@state/authPhone'
import { authPageStateAtom } from '@state/authPage'
import { authOpenStateAtom } from '@state/authOpen'

import { policyStateAtom } from '@state/policy'

import { userDataStateAtom } from '@state/userData'

import MuiPhoneNumber from 'material-ui-phone-number'

import { useForm, Controller } from 'react-hook-form'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import Divider from '@mui/material/Divider';

import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import SendIcon from '@mui/icons-material/Send'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import Typography from '@mui/material/Typography'

import DoneIcon from '@mui/icons-material/Done'

import useMobileDetect from '@hooks/useMobileDetect'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import api from '@services/api'
import { MD5 } from 'crypto-js'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

const Schema = yup.object().shape({
  phone: yup.string()/* .min(18) */.required("Необходимо ввести телефон"),
  /* password: yup.string().matches(passwordRules, { message: "Необходимо ввести пароль" }).required("Необходимо ввести пароль"), */
})

const Login: FC<any> = () => {

  const mobile = useMobileDetect()

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ send, setSend ] = useState<boolean>(false)

  const [ open, setOpen ] = useRecoilState<boolean>(authOpenStateAtom)

  const [ failAuth, setFailAuth ] = useState<boolean>(false)

  const [ phone , setPhone ] = useRecoilState<string>(authPhoneStateAtom)
  const setAuthPage = useSetRecoilState<string>(authPageStateAtom)

  const [ jwt, setJwt ] = useRecoilState<boolean>(jwtStateAtom)
  const [ userData, setUserData ] = useRecoilState<boolean>(userDataStateAtom)

  const [ policy, setPolicy ] = useRecoilState<any>(policyStateAtom)

  
  const { handleSubmit, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      phone: '',
      /* password: '', */
    }
  })
/* 
  useEffect(() => {

    if(!send) return;

      setTimeout(()=>{

        setSend(false)
        setOpen(false)
      }, 2500)
  }, [send, open]) */

  const formatPhoneNumber = (phone: string): string => {
    return phone.replace(/\D/g, '').replace(/^7/, '7');
  };
  
  

  const onSubmit = async (data:any) => {

    window?.navigator?.vibrate && window?.navigator?.vibrate(10);
    setLoading(true)

    

    console.log("getValues('phone')")
    console.log(getValues('phone'))

    const formattedPhone = formatPhoneNumber(getValues('phone'));
    console.log('formattedPhone')
    console.log(formattedPhone)
    console.log(MD5(getValues('phone')).toString())

    setPhone(formattedPhone)
    setAuthPage('phoneConfirmed')

    
    

    /* api({
      method: 'POST',
      collection: 'auth/local', 
      data: { 
        identifier: formattedPhone, 
        password: MD5(getValues('phone')).toString(), 
        //password: getValues('password'), 
      }
    }).then((data) => {

      setSend(true)
      setLoading(false)

      if(data&&data.jwt){

        setJwt(data.jwt)
        setUserData(data.user)

        return setOpen(false)
      }

      return alert('Неверные данные')

    }).catch((error:any) => {

      setLoading(false)

      if(error?.response?.data?.error?.message==='Invalid identifier or password'){

        setFailAuth(true)

        setTimeout(()=>{
          setFailAuth(false)
        }, 4500)
      }
    }) */
  }

  const onClose = () => setOpen(false);

  return <>
            
      <Typography sx={{...(!mobile && { m: 2.5, mt: 0, }), textAlign: "center" ,  typography: {  xs: 'h6', sm: 'h6', md: 'h6', lg: 'h6' }}}>

        Вход и регистрация
        
      </Typography>

      <Typography sx={{...(!mobile && { m: 2.5, mt: 0, mb: 5 }), textAlign: "center" ,  typography: {  xs: 'body1', sm: 'body1', md: 'body1', lg: 'body1' }}}>

        {/* для входа или регистрации введите ваш мобильный номер телефона */}
        
        введите ваш мобильный номер

      </Typography>

      <Box
        component="form"
        sx={{
          width: '100%',
          padding: 0,
          '& > :not(style)': { mt: 1, mb: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >

        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <MuiPhoneNumber
            variant="outlined"
            defaultCountry={'ru'}
            regions={'europe'}
            onChange={onChange}
            value={value}
            onKeyDown={(e:any) => {
              console.log(`Pressed keyCode ${e.key}`);
              if (e.key === 'Enter') {
                onSubmit(e);
                e.preventDefault();
              }
            }}
            />
          )}
        />
        
        {errors?.phone && errors.phone.type === "required" && <Typography variant="overline" color="error">{errors.phone.message}</Typography>}
        {errors?.phone && errors.phone.type === "min" && <Typography variant="overline" color="warning">телефон должен быть не менее 11 символов</Typography> }
 
{/* 
        <Controller
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, isDirty, error },
          }) => (
            <TextField
              label="Пароль"
              type="password"
              value={value}
              onBlur={onBlur}
              inputRef={ref}
              onChange={(e: any) => {setFailAuth(false); onChange(e?.target?.value)}}
            />
          )}
          name="password"
          control={control}
          rules={{ required: true }}
        />

        {errors?.password && <Typography variant="overline" color="error">{errors.password.message}</Typography>}
 */}

        <LoadingButton
        sx={{
/*           bgcolor: 'primary.company',
          "&:hover": {
            bgcolor: 'primary.company',
            opacity: 0.7,
          },
          "&:active": {
            bgcolor: 'primary.company',
            opacity: 0.7,
          },
          '& .MuiButton-endIcon': {
            marginTop: -0.5,
          } */


        }}
        loading={loading} variant="contained" onClick={handleSubmit(onSubmit)} endIcon={<SendIcon />}>Продолжить</LoadingButton>
        

      </Box>
{/*     
      {failAuth && <Typography sx={{display: 'block', textAlign: "center", width: '100%'}} variant="overline" color="error">Неверные данные</Typography>}



      <Divider sx={{ mt: 2.5, mb: 2.5 }}>или</Divider>



      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>

        <Grid container xs={12}>

          <Grid xs={6}>
            <Typography sx={{...(!mobile && { mt: 0, }), textAlign: "left", position: 'relative', top: '50%', transform: 'translateY(-50%)',  typography: {  xs: 'body2', sm: 'body2', md: 'body2', lg: 'body2' }}}>
              
              Восстановить пароль
            
            </Typography>
          </Grid>

          <Grid xs={6} sx={{textAlign: 'right'}}>
            <Button
              sx={{
                bgcolor: 'rgba(44,44,44, 0.4)',
                "&:hover": {
                  bgcolor: 'rgba(44,44,44, 0.4)',
                  opacity: 0.7,
                },
                "&:active": {
                  bgcolor: 'rgba(44,44,44, 0.4)',
                  opacity: 0.7,
                },
              }}
              variant="outlined" onClick={()=>{}}>
                
                Зарегистрироваться
                  
            </Button>
          </Grid>
        
        </Grid>

      </Box>



      <Divider/>

 */}

      <Grid sx={{ textAlign: 'center', mt: 2.5 }}>  
        <Typography  variant="caption" color="textSecondary" >Нажимая на кнопку "Продолжить", вы даете согласие на <b onClick={() => setPolicy(true)}>обработку персональных данных</b> и соглашаетесь c <b onClick={() => setPolicy(true)}>политикой конфиденциальности.</b></Typography>
      </Grid>

    </>
  
}

export default Login
