import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const selectCategoryForAddProductStateAtom = atom({
  key: "selectCategoryForAddProductStateAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],

})

export const selectCategoryForAddProductStateSelector = selector({
  key: "selectCategoryForAddProductStateSelector",
  get: ({ get }) => {
    const selectCategoryForAddProduct = get(selectCategoryForAddProductStateAtom)
    return selectCategoryForAddProduct
  }
})

