import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const shopOpenPageStepStateAtom = atom({
  key: "shopOpenPageStepStateAtom",
  default: 'categories',// categories, products, product
  effects_UNSTABLE: [persistAtom],

})

export const shopOpenPageStepStateSelector = selector({
  key: "shopOpenPageStepStateSelector",
  get: ({ get }) => {
    const shopOpenPageStep = get(shopOpenPageStepStateAtom)
    return shopOpenPageStep
  }
})

