import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const policyStateAtom = atom({
  key: "policyStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],

})

export const policyStateSelector = selector({
  key: "policyStateSelector",
  get: ({ get }) => {
    const policy = get(policyStateAtom)
    return policy
  }
})

