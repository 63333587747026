
import { FC, useEffect, useState, useRef } from 'react'

import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import { arrayMove } from 'react-sortable-hoc';

import axios from 'axios'
import qs from 'qs'

import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import { jwtStateAtom } from '@state/jwt'
import { userDataStateAtom } from '@state/userData'

import { shopStateAtom } from '@state/shop'
import { pageStateAtom } from '@state/page'
import { productSortStateAtom } from '@state/productSort'

import { selectCategoryForAddProductStateAtom } from '@state/selectCategoryForAddProduct'

import { productEditStateAtom } from '@state/productEdit'


import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'
import { globalProgressBackdropTextStateAtom } from '@state/globalProgressBackdropText'

import Modal from '@mui/material/Modal';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import Badge from '@mui/material/Badge';

import Grid from '@mui/material/Unstable_Grid2'
import Snackbar from '@mui/material/Snackbar';

import TextField from '@mui/material/TextField'



import CircularProgress from '@mui/material/CircularProgress'

import TuneIcon from '@mui/icons-material/Tune';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import NearMeIcon from '@mui/icons-material/NearMe';

import styles from './styles.module.scss'

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import api from '@services/api'

import { useForm, Controller } from 'react-hook-form'

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '@mui/icons-material/Settings';
import BackupIcon from '@mui/icons-material/Backup';


import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести название"),
  description: yup.string().required("Необходимо ввести описание"),
  sku: yup.string(),
  price: yup.number(),
  stock: yup.boolean(),
  sizes: yup.string(),
})

async function createThumbnail(file: File, maxWidth: number, maxHeight: number): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result as string;
    };

    reader.onerror = error => reject(error);
    img.onerror = error => reject(error);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return reject(new Error('Canvas context is not available'));
      }

      // Определяем коэффициент масштабирования
      const scale = Math.min(maxWidth / img.width, maxHeight / img.height);

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Конвертируем canvas в Blob
      canvas.toBlob(blob => {
        if (blob) {
          // Создаем объект File из Blob
          const thumbnailFile = new File([blob], `thumbnail_${file.name}`, { type: blob.type });
          resolve(thumbnailFile);
        } else {
          reject(new Error('Conversion to Blob failed'));
        }
      }, file.type || 'image/jpeg');
    };

    reader.readAsDataURL(file);
  });
}


const width = 100;
const height = 160;


// Типизация для пропсов SortableItem
interface SortableItemProps {
  value: any;
  index: number;
  handleDeleteFile: any;
}

const SortableItem = SortableElement<SortableItemProps>(({ value, index, handleDeleteFile }: SortableItemProps) => {

  return (

    <Box>

      { (value.fileType?.includes('image')) &&

        <Box
        className="card-panel"
        sx={{
          position: 'relative',
          minWidth: width,
          minHeight: height,
          m: 1,
          borderRadius: 3,
          overflow: 'hidden'
        }}>
          <Box
          sx={{
            backgroundImage:`url(${value?.fileObject})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minWidth: width,
            minHeight: height,
          }}/>

          <Button variant="contained" onClick={()=>{ handleDeleteFile({id: value?.id}) }} sx={{ position: 'absolute', top: 5, right: 5, p: 0, minWidth: 25, height: 25, }}>
            <ClearIcon/>
          </Button>
        </Box>
      }

      {(value.fileType?.includes('video')) &&

        <Box
        className="card-panel"
        sx={{
          position: 'relative',
          width: width,
          height: height,
          minWidth: width,
          minHeight: height,
          m: 1,
          borderRadius: 3,
          overflow: 'hidden'
        }}>
          <CardMedia
          component='video'
          image={value?.fileObject}
          src={value?.fileObject}
          /* autoPlay
          loop */
          muted
          sx={{
            minWidth: width,
            minHeight: height,
          }}
          />

          <Button variant="contained" onClick={()=>{ handleDeleteFile({id: value?.id}) }} sx={{ position: 'absolute', top: 5, right: 5, p: 0, minWidth: 25, height: 25 }}>
            <ClearIcon/>
          </Button>
        </Box>
      }
    </Box>
  );
});


interface SortableListProps {
  items: any[];
  onSortEnd: SortEndHandler;
  handleClickAddFile: any;
  handleDeleteFile: any;
}

const SortableList = SortableContainer<SortableListProps>(({ items, handleClickAddFile, handleDeleteFile }: SortableListProps) => {

return (
        <Box sx={{
          width: '100%', height: 'auto', maxWidth: 'calc(100vw - 30px)', mb: 2, 
          display: 'flex', alignItems: 'center', justifyContent:  'center', flexWrap: 'wrap', overflow: 'auto',
          scrollbarWidth: "none", '&::-webkit-scrollbar': { display: 'none' }, '&::-ms-overflow-style': { display: 'none' },
        }}>

          {items.map((value:any, index:number) => (
            <SortableItem key={`item-${index}`} index={index} value={value} handleDeleteFile={handleDeleteFile}/>
          ))}

          <Box onClick={handleClickAddFile} sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minWidth: width, width: width, minHeight: 150, height: 150, cursor: 'pointer' }}>
            
            <AddAPhotoIcon fontSize='large'/>

            <Typography sx={{ fontSize: 12, mt: 1, }}>Добавить</Typography>
            <Typography sx={{ fontSize: 12, mt: 0, }}>Фото/Видео</Typography>

          </Box>
        </Box>
      )
});

const AddProduct: FC<any> = () => {

  const [nameProduct, setNameProduct] = useState<any>(null)
  const [descriptionProduct, setDescriptionProduct] = useState<any>(null)

  const [sku, setSku] = useState<any>(null)
  const [price, setPrice] = useState<any>(null)
  const [stock, setStock] = useState<any>(true)
  const [sizes, setSizes] = useState<any>(null)
  
  const selectCategoryForAddProduct = useRecoilValue(selectCategoryForAddProductStateAtom)
  const [productEdit, setProductEdit] = useRecoilState<any>(productEditStateAtom)
  
  const [globalProgressBackdrop, setGlobalProgressBackdrop] = useRecoilState<boolean>(globalProgressBackdropStateAtom)
  const setGlobalProgressBackdropText = useSetRecoilState<string|null>(globalProgressBackdropTextStateAtom)

  const [selectNextAction, setSelectNextAction] = useState<boolean>(false)

  const [userName, setUserName] = useState<any>(null)
  const [userNameNotFree, setUserNameNotFree] = useState<boolean>(true)

  const resetProductSort = useResetRecoilState(productSortStateAtom)

  const [data, setData] = useRecoilState<any>(userDataStateAtom)

  const setShop = useSetRecoilState(shopStateAtom)
  const setPage = useSetRecoilState(pageStateAtom)

  const [openConfirmDeleteProduct, setOpenConfirmDeleteProduct] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [jwt, setJwt] = useRecoilState(jwtStateAtom)

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const [selectedFiles, setSelectedFiles] = useState<any>(undefined);
  const [imagePreviews, setImagePreviews] = useState<any>([]);

  const [files, setFiles] = useState<any>([]);

  const handleSubmitFile = async ({id}:{id:number}) => {

    let newFiles = [...imagePreviews];
    
    for(const item of newFiles) {
    
      if(!item?.upload) {
    
        const formData = new FormData();
        formData.append('files', item.file);
        formData.append('ref', 'api::product.product');
        formData.append('refId', id.toString()); // тут нужно будет указать ид созданного продукта в страпи
        formData.append('field', 'files');
        
        try {

          const uploadResponse = await axios.post('/api/upload/', formData, {
            headers: {
              'Authorization': `Bearer ${jwt}` 
            }
          });
    
          const uploadData = uploadResponse.data[0];

          setFiles({
            ...files,
            ...{...uploadData}
          })

          newFiles = newFiles.map((imagePreview:any) => {
            
            if(imagePreview?.id === item?.id){
              
            return {
              ...imagePreview,
              ...{upload: true, id: uploadData?.id}
            }
            }
            return {
              ...imagePreview,
            }
          })
          
        } catch (error) {
          
          console.error('Error:', error);
        }
      }
    }

    setImagePreviews(newFiles)

    const idOrder = imagePreviews.reduce((acc:any, item:any, index:any) => {
      acc[item.id] = index;
      return acc;
    }, {});

    const sortedSecondArray = newFiles.sort((a: any, b: any) => idOrder[a.id] - idOrder[b.id]);

    api({
      method: 'PUT',
      collection: `products/${id}`,
      data: {
        data:{
          files: sortedSecondArray
        }
      },
      authorization: jwt
    }).then(async (data:any) => {

      setGlobalProgressBackdrop(false)
      
    }).catch((error:any) => {

      if(error?.response?.status===401) setJwt(null);
    })
  };

  const handleFileChange = async (e:any) => {

    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {

      const file = e.target.files[i];
      const fileType = file.type;

      if(fileType.includes('image')){

        const resizeImageData = await createThumbnail(file, 300, 300);
  
        images.push({
          id: Math.floor(Math.random() * 99999999999999999999),
          upload: false,
          file: resizeImageData,
          fileType: fileType ? fileType : null,
          fileObject: URL.createObjectURL(resizeImageData),
        });
      }else

      if(fileType.includes('video')){

        images.push({
          id: Math.floor(Math.random() * 99999999999999999999),
          upload: false,
          file: file,
          fileType: fileType ? fileType : null,
          fileObject: URL.createObjectURL(file),
        });
      }
    }

    setSelectedFiles(e.target.files);
    setImagePreviews([...imagePreviews, ...images]);
  };

  const handleClickAddFile = (event:any) => {

    hiddenFileInput?.current?.click();
  };

  const handleDeleteFile = async ({id}:{id:number}) => {

    for(const item of imagePreviews) {

      if(item?.id === id) {
        if(item?.upload) {
                  
          api({
            method: 'DELETE',
            collection: `upload/files/${item?.id}`,
            data: {},
            authorization: jwt
          }).then((data:any) => {
      
            console.log(`data`)
            console.log(data?.data)
            
          }).catch((error:any) => {
      
            if(error?.response?.status===401) setJwt(null);
          })
        }
      }
    }

    const filteredFiles = imagePreviews.filter((item:any) => item.id !== id)

    setImagePreviews([...filteredFiles]);
  };

  const addSaveProduct = async () => {

    setGlobalProgressBackdropText('Дождитесь завершения загрузки...')
    setGlobalProgressBackdrop(true)

    if(!productEdit){
    
      api({
        method: 'POST',
        collection: 'products',
        data: {
          data:{
            user: data?.id,
            shop: data?.shop?.id,
            name: nameProduct, 
            description: descriptionProduct, 
            sku: sku, 
            price: price, 
            stock: stock, 
            sizes: sizes, 
            category: {
              id: selectCategoryForAddProduct?.id
            }, 
          }
        },
        authorization: jwt
      }).then(async (data:any) => {
  
        console.log(`data`)
        console.log(data?.data)
  
        if(data?.data?.id) {
  
          await handleSubmitFile({id: data?.data.id})
  
          setTimeout(() => {
                  
            setGlobalProgressBackdropText(null)
            setGlobalProgressBackdrop(false)
  
            setSelectNextAction(true)
  
          }, 1000*1)
        };
        
      }).catch((error:any) => {
  
        if(error?.response?.status===401) setJwt(null);
      })

    }else{
      
      api({
        method: 'PUT',
        collection: `products/${productEdit?.id}`,
        data: {
          data:{
            user: data?.id,
            name: nameProduct, 
            description: descriptionProduct, 
            sku: sku, 
            price: price, 
            stock: stock, 
            sizes: sizes,
            category: {
              id: selectCategoryForAddProduct?.id
            }, 
          }
        },
        authorization: jwt
      }).then(async (data:any) => {

        console.log(`data`)
        console.log(data?.data)

        if(data?.data?.id) {

          await handleSubmitFile({id: data?.data.id})

          setTimeout(() => {
                  
            setGlobalProgressBackdropText(null)
            setGlobalProgressBackdrop(false)

            setSelectNextAction(true)
          }, 1000*1)
        };
        
      }).catch((error:any) => {

        if(error?.response?.status===401) setJwt(null);
      })
    }
  }

  const deleteProduct = async () => {

    setGlobalProgressBackdropText('Дождитесь завершения загрузки...')
    setGlobalProgressBackdrop(true)

    if(productEdit){
    
      api({
        method: 'DELETE',
        collection: `products/${productEdit?.id}`,
        data: {},
        authorization: jwt
      }).then((data:any) => {
  
        console.log(`data`)
        console.log(data?.data)

        setTimeout(() => {
                
          setGlobalProgressBackdropText(null)
          setGlobalProgressBackdrop(false)

          setSelectNextAction(true)

        }, 1000*1)
      
      }).catch((error:any) => {
  
        if(error?.response?.status===401) setJwt(null);
      })

      return;
    }
  }
    
  useEffect(() => {

    if(!productEdit || !productEdit?.id) return;

    const query = qs.stringify({
      populate: {
        name: true,
        price: true,
        stock: true,
        sizes: true,
        sku: true,
        description: true,
        files: {
          populate: '*'
        }
      },
    }, {
      encodeValuesOnly: true,
    })

    api({ collection: `products/${productEdit?.id}`, query, authorization: jwt }).then(async (data:any) => {

      if(data?.data?.id){

        setNameProduct(data?.data?.name)
        setDescriptionProduct(data?.data?.description)
        setSku(data?.data?.sku)
        setPrice(data?.data?.price)
        setStock(data?.data?.stock)
        setSizes(data?.data?.sizes)

        let images = [];

        if(data?.data?.files?.length>0){

          for(const item of data?.data?.files){

            const response = await fetch(item?.url);
            const blob = await response.blob();
            
            // Извлекаем имя файла из URL (например, example.jpg)
            const fileName = item?.name;
            // Создаем объект File
            const newFile = new File([blob], fileName, { type: blob.type });

            const file = newFile;
            const fileType = file.type;
      
            const newImage = {
              id: item?.id,
              upload: true,
              file: file,
              fileType: fileType ? fileType : null,
              fileObject: URL.createObjectURL(file),
            };
            
            if (fileType.includes('image') || fileType.includes('video')) {
              // Проверка на дубли
              const isDuplicate = imagePreviews.some((image: any) => image.id === newImage.id);
              
              if (!isDuplicate) {
                images.push(newImage);
              }
            }
            
            setImagePreviews([...imagePreviews, ...images]);
          }
        }
      }
      
      setLoading(false)

    }).catch((error:any) => {

      if(error?.response?.status===401){

        setJwt(null)
      }

      setLoading(false)
    })
  }, [])

  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      user: null,
      name: '',
      description: '',
      sku: '',
      price: null,
      stock: true,
      sizes: '',
    }
  });
  
  const handleLogout = async () => {

    setData({});
    setJwt(null);
  };

  const handleOpenProducts = async () => {

  };

  const handleOpenSelectCategoryForAddProduct = async () => {

    setPage('selectCategoryForAddProduct')
  };

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {

    setImagePreviews(arrayMove(imagePreviews, oldIndex, newIndex));
  };

  return (
    <> 
      <Container maxWidth="sm" sx={{mt:0, pt:0, pb: 1, pl: 3, pr: 3}}>

        <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" sx={{
          height: 'calc(100vh - 60px)', overflowY: 'auto', pt: 2,  pb: 2,  
          scrollbarWidth: "none", '&::-webkit-scrollbar': { display: 'none' }, '&::-ms-overflow-style': { display: 'none' },
        }}>

          <Typography variant="h6">

          { !productEdit ? 'Добавление' : 'Редактирование' }

          </Typography>

          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap:"wrap" }}>

            <input type='file' accept='image/*, video/*' onChange={handleFileChange} multiple ref={hiddenFileInput} style={{ display: 'none' }}/>

            {/* { !productEdit &&
            <> */}

              <SortableList items={imagePreviews} pressDelay={100} onSortEnd={onSortEnd} axis="xy" handleClickAddFile={handleClickAddFile} handleDeleteFile={handleDeleteFile}/>
              <Typography sx={{ fontSize: 12, mb: 2, }}>Используйте вертикальные видео и фото файлы</Typography>

            {/* </>
            } */}

            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column" sx={{ width: '100%',  }}>

              <TextField
                disabled

                sx={{ width: '100%', userSelect: 'none',  }}
                
                size="small"
                margin="normal"
                variant="outlined"

                onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                autoFocus={false}

                label="Категория"
                //placeholder="Название"

                value={`${selectCategoryForAddProduct?.name}`}

                InputProps={{
                  endAdornment: (
                    
                    <InputAdornment position="start" sx={{  }}>

                      <SettingsIcon onClick={(e:any)=>{

                        setPage('selectCategoryForAddProduct')
                      }}/>
                    </InputAdornment>
                  ),
                }}
              />

              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField

                    sx={{ width: '100%',  }}
                    
                    size="small"
                    margin="normal"
                    variant="outlined"

                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    autoFocus={false}

                    label={"Название"}
                    //placeholder="Название"

                    value={nameProduct}

                    onBlur={onBlur}
                    inputRef={(input) => {

                      ref(input);
                      //if (input) searchFormTextFieldRef.current = input;
                    }}

                    onChange={(e:any)=>{

                      setNameProduct(e.target.value)
                    }}
                    onFocus={(e:any)=>{}}
                    InputLabelProps={{ shrink: nameProduct,  sx: { marginTop: 0.5 } }}

                  />
                )}
                name="name"
                control={control}
                rules={{ required: true }}
              />

              <FormGroup sx={{ '& .MuiButtonBase-root': { height: 'unset' } }}>
                <FormControlLabel control={<Switch checked={stock} onChange={(e:any)=>{
                  setStock(e.target.checked)
                }}/>} label="В наличии" />
              </FormGroup>


              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField

                    sx={{ width: '100%',  }}
                    
                    size="small"
                    margin="normal"
                    variant="outlined"

                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    autoFocus={false}

                    label={"Цена"}
                    //placeholder="Название"

                    value={price}

                    onBlur={onBlur}
                    inputRef={(input) => {
                      ref(input);
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const filteredValue = e.target.value.replace(/\D/g, ''); // Удаление всех нецифровых символов
                      setPrice(filteredValue); // Обновление значения контроллера
                    }}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {}}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Установка режима ввода только цифр
                    InputLabelProps={{ shrink: price,  sx: { marginTop: 0.5 } }}
                  />
                )}
                name="price"
                control={control}
                rules={{ required: true }}
              />

               <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField

                    sx={{ width: '100%',  }}
                    
                    size="small"
                    margin="normal"
                    variant="outlined"

                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    autoFocus={false}

                    label={"Размеры в наличии (через запятую)"}
                    //placeholder={ productEdit && "Размеры в наличии (через запятую)"}

                    value={sizes}

                    onBlur={onBlur}
                    inputRef={(input) => {

                      ref(input);
                      //if (input) searchFormTextFieldRef.current = input;
                    }}

                    onChange={(e:any)=>{

                      setSizes(e.target.value)
                    }}
                    onFocus={(e:any)=>{}}
                    InputLabelProps={{ shrink: sizes, sx: {  marginTop: 0.5 } }}

                  />
                )}
                name="sizes"
                control={control}
                rules={{ required: true }}
              />

              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField

                    sx={{ width: '100%',  }}
                    
                    size="small"
                    margin="normal"
                    variant="outlined"

                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    autoFocus={false}

                    label={"Артикул"}
                    //placeholder={ productEdit && "Название"}

                    value={sku}

                    onBlur={onBlur}
                    inputRef={(input) => {

                      ref(input);
                      //if (input) searchFormTextFieldRef.current = input;
                    }}

                    onChange={(e:any)=>{

                      setSku(e.target.value)
                    }}
                    onFocus={(e:any)=>{}}
                    InputLabelProps={{ shrink: sku, sx: {  marginTop: 0.5 } }}
                  />
                )}
                name="sku"
                control={control}
                rules={{ required: true }}
              />

              <Controller

                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField

                    sx={{
                      width: '100%', height: 'auto',
                      '& .MuiOutlinedInput-root': { height: 'auto', }
                    }}

                    multiline
                    rows={4}
                    maxRows={15}
                    
                    margin="normal"
                    variant="outlined"

                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    autoFocus={false}

                    label={ "Описание"}
                    //placeholder="Описание"

                    value={descriptionProduct?.replace(/(<([^>]+)>)/gi, "")}

                    onBlur={onBlur}
                    inputRef={(input) => {

                      ref(input);
                      //if (input) searchFormTextFieldRef.current = input;
                    }}

                    onChange={(e:any)=>{

                      setDescriptionProduct(e.target.value)
                    }}
                    InputLabelProps={{ shrink: descriptionProduct, sx: {  marginTop: 0.5 } }}

                  />
                )}
                name="description"
                control={control}
                rules={{ /* required: true */ }}
              />

              <Typography variant="body2" sx={{ color: 'red', marginTop: 1 }}>

                {!userNameNotFree && 'Занято'}

              </Typography>

            </Box>

          </Box>
          
          <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" sx={{ /* width: 250, border: '1px solid grey', borderRadius: 5, p: 2,  */'& > *': { m: '5px !important' },  }}>

            {/* <Button variant="contained" onClick={()=>{handleOpenProducts()}} sx={{ width: 80, }}>
              <ClearIcon/>
            </Button> */}

          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" sx={{ width: '100%', '& > *': { m: '5px !important' }, }}>

            { productEdit && 
            <Button fullWidth variant="contained" onClick={()=>{
              
              setOpenConfirmDeleteProduct(!openConfirmDeleteProduct)
            }}>

              Удалить

            </Button>
            
            }
        
            <Button fullWidth variant="contained" onClick={()=>{
              
              addSaveProduct()
            }}>

              {productEdit ? 'Сохранить' : 'Добавить'}

            </Button>

            </Box>

          {/* 
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" sx={{ '& > *': { m: '5px !important' }, }}>

              <Button variant="contained" onClick={()=>{resetProductSort()}} sx={{ width: 180, }}>Сброс сортировки</Button>

              <Button variant="contained" onClick={()=>{ setData({}); setJwt(null); }} sx={{ width: 180, }}> Выйди </Button>

            </Box>
          */}

        </Box>
        
      </Container>

      <Modal
        open={selectNextAction}
        onClose={()=>{}}
        sx={{ borderRadius: 5 }}
      >

        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 360,
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          boxShadow: 12,
          borderRadius: 5,
          p: 4,
        }}>

          { productEdit ?

          <>

          <Typography variant="h6" component="h2" sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'center', }}>

            Товар обновлен!

          </Typography>

          <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" sx={{
          '& > *': { m: '5px !important',  height: 'auto' },
          }}>

            <Button fullWidth variant="contained" onClick={()=>{

              setSelectNextAction(false)
              setNameProduct('')
              setDescriptionProduct('')
              setSku('')
              setPrice(0)
              setStock(true)
              setSizes('')

              setSelectedFiles(undefined);
              setImagePreviews([])

              setProductEdit(null)
              setPage('catalog')
            }}>

              Вернуться в каталог

            </Button>

            <Button fullWidth variant="contained" onClick={()=>{

              setSelectNextAction(false)
            }}>

                Продолжить редактирование

            </Button>

          </Box>

          </>
          :
          <>
          <Typography variant="h6" component="h2" sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'center', }}>

            Товар добавлен!

          </Typography>

          <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" sx={{
          '& > *': { m: '5px !important',  height: 'auto' },
          }}>

            <Button fullWidth variant="contained" onClick={()=>{

              setSelectNextAction(false)
              setNameProduct('')
              setDescriptionProduct('')
              setSku('')
              setPrice(0)
              setStock(true)
              setSizes('')

              setSelectedFiles(undefined);
              setImagePreviews([])

              setPage('account')
            }}>

              Вернуться в профиль

            </Button>

            <Button fullWidth variant="contained" onClick={()=>{

              setSelectNextAction(false)
              setNameProduct('')
              setDescriptionProduct('')
              setSku('')
              setPrice(0)
              setStock(true)
              setSizes('')

              setSelectedFiles(undefined);
              setImagePreviews([])
            }}>
              
              Добавить следующий
              
            </Button>

          </Box>

          </>
        
          }

        </Box>

      </Modal>

      <Dialog
        open={openConfirmDeleteProduct}
        onClose={()=>{ setOpenConfirmDeleteProduct(!openConfirmDeleteProduct) }}
      >
        <DialogTitle>

          Удаление товара

        </DialogTitle>
        <DialogContent>
          <DialogContentText>

            Это действие необратимо

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{

            setOpenConfirmDeleteProduct(!openConfirmDeleteProduct)
          }}>
            
            Отменить
            
          </Button>

          <Button onClick={()=>{

            setProductEdit(null)

            deleteProduct()
            setOpenConfirmDeleteProduct(!openConfirmDeleteProduct)

            setPage('catalog')
          }} autoFocus>

            Подтвердить

          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddProduct
