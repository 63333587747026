
import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const catalogShopProductEditStateAtom = atom({
  key: "catalogShopProductEditStateAtom",
  default: false,
  effects_UNSTABLE: [persistAtom],
})

export const catalogShopProductEditStateSelector = selector({
  key: "catalogShopProductEditStateSelector",
  get: ({ get }) => {
    const catalogShopProductEdit = get(catalogShopProductEditStateAtom)
    return catalogShopProductEdit
  }
})

