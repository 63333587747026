import { FC, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { pageStateAtom } from '@state/page'

import api from '@services/api'

const Start: FC<any> = () => {

  const [page, setPage] = useRecoilState<any>(pageStateAtom)

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center" 
      height="calc(100vh - 0px)"
    >

     тут стартовый слайдер ыы

     <Button sx={{ color: 'black'}} onClick={()=>{
       setPage('auth')
     }}>
      
        авторизация
      
      </Button>

    </Box>
  )
}

export default Start
