import { FC, ReactNode, useRef, useEffect, useState, Children } from 'react'

import Box from '@mui/material/Box'

import { styled } from '@mui/system';

const DraggableContainer = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  top: 0,
  left: 0,
  'pointer-events': 'none',
  scrollbarWidth: "none",
  '&::-webkit-scrollbar': { display: 'none', },
  '&-ms-overflow-style:': { display: 'none', },
});

const DraggableBox = styled(Box)<{ isHidden: boolean }>(({ isHidden }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-out',
  transform: isHidden ? 'translateX(100%)' : 'translateX(0)',
  display: 'flex',
  alignItems: 'flex-start', //center
  justifyContent: 'center',
  'pointer-events': 'auto',
  overflow: 'hidden',
  scrollbarWidth: "none",
  '&::-webkit-scrollbar': { display: 'none', },
  '&-ms-overflow-style:': { display: 'none', },

}));

interface I {
  open?: boolean;
  setOpen?: any;
  children: ReactNode;
}

const Draggable: FC<I> = ({open, setOpen, children}) => {

  const [position, setPosition] = useState<number>(0);
  const [initialX, setInitialX] = useState<number | null>(null);

  const handleStart = (clientX: number) => {
    setInitialX(clientX);
  };

  const handleMove = (clientX: number) => {
    if (initialX !== null && open) {
      const newPosition = clientX - initialX;
      if (newPosition >= 10) {
        setPosition(newPosition);
      }
    }
  };

  const handleEnd = () => {
    if (open) {
      const boxWidth = 300; // ширина Box

      if (position > boxWidth / 3) {
        setPosition(boxWidth);
        setOpen(false);
      } else {    
        setPosition(0);
      }
      setInitialX(null);
    }
  };

  useEffect(() => {

    if(open) return setPosition(0);

    return () => {
      console.log('wtf')
    };
    
  },[open])

  useEffect(() => {},[position])

  const handleMouseDown = (event: React.MouseEvent) => handleStart(event.clientX);

  const handleMouseMove = (event: MouseEvent) => handleMove(event.clientX);

  const handleMouseUp = () => handleEnd();

  const handleTouchStart = (event: React.TouchEvent) => {

    console.log('handleTouchStart')
    if (event.touches.length === 1) {
      handleStart(event.touches[0].clientX);
    }
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (event.touches.length === 1) {
      handleMove(event.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => handleEnd();
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [initialX,position, open]);

  const handlePointerDown = (event: React.PointerEvent) => {
    handleStart(event.clientX);
  };

  const handlePointerMove = (event: PointerEvent) => {
    handleMove(event.clientX);
  };

  const handlePointerUp = () => {
    handleEnd();
  };

  return (
    <>
      <DraggableContainer>
        <DraggableBox
          isHidden={!open}
          onPointerDown={handlePointerDown}
          style={{ left: `${position}px` }}
        >
          {children}
        </DraggableBox>
      </DraggableContainer>
    </>
  )
}

export default Draggable

