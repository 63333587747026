import { FC } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const Loading: FC = () => {

  return (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    sx={{
      minHeight: 180,
    }}>

    <CircularProgress color="inherit"
    sx={{
      top: 150, 
      transform: 'translateY(-50%)',
    }} />
    
  </Box>
  )
}

export default Loading
