import { FC, useEffect,useState  } from 'react'

import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

const Logo: FC<any> = () => {

  const [isFullscreen, setIsFullscreen] = useState(false);

  // Watch for fullscreenchange
  useEffect(() => {
    
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
          
    document.addEventListener('fullscreenchange', onFullscreenChange);
  
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  return null;
  
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      /* width: 200, */
      height: 35,
      top: 10,
      left: '50%',
      pl: 2,
      pr: 2,
      transform: 'translateX(-50%)',
      position: 'fixed',
      zIndex: 2,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05)',
      borderRadius: 10,
    }}
    onClick={
      () => {

        if(!isFullscreen) return document.body.requestFullscreen();
        if(isFullscreen) return document.exitFullscreen();
      }
    }
    >

      uMapShops

    </Box>
  )
}

export default Logo
