
import { FC, useState } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'

import { authPageStateAtom } from '@state/authPage'
import { authOpenStateAtom } from '@state/authOpen'

import Container from '@mui/material/Container';
import { Box } from '@mui/material';

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import LoginOrRegister from './LoginOrRegister'
import PhoneConfirmed from './PhoneConfirmed'

const Auth: FC<any> = () => {

  const [ open, setOpen ] = useRecoilState<boolean>(authOpenStateAtom)

  const authPage = useRecoilValue(authPageStateAtom)

  const onClose = () => setOpen(false);

  return  <Container maxWidth="sm" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
{/* <DesktopOrMobileOrBot open={open || false} onClose={onClose}>  */}
            <Box>
            {authPage === 'loginOrRegister' && <LoginOrRegister/>}

            {authPage === 'phoneConfirmed' && <PhoneConfirmed/>}  
            </Box>
{/*  </DesktopOrMobileOrBot> */}
          </Container>


        
}

export default Auth
