import { FC, ReactNode  } from 'react'

import { useRecoilValue } from 'recoil'

import { effectsStateAtom } from '@state/effects'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import useMediaQuery from '@mui/material/useMediaQuery'

import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

interface I {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  anchor: "top" | "left" | "right" | "bottom" | undefined;
  disableSwipeToOpen?: boolean;
  allowSwipeInChildren?: boolean;
  transitionDuration?: any,
  sx?: any;
  maxWidth?: boolean;
  minHeight?: any;
  maxHeight?: any;
  PaperProps?: any; // Include a '?' before the colon to make the property optional
  ModalProps?: any;
  container?: any;
  swipeAreaWidth?: any;
  children: ReactNode;
  onClick?: any;
}

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));
const PullerBottom = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  bottom: 8,
  left: 'calc(50% - 15px)',
}));


const CustomSwipeableDrawer: FC<I> = ({ open=false, onOpen=()=>{}, onClose=()=>{}, sx, maxWidth, minHeight='30vh', maxHeight='100vh', anchor='top', disableSwipeToOpen=true, allowSwipeInChildren=false, transitionDuration, children, swipeAreaWidth, container=null, PaperProps, ModalProps, onClick }) => {

  const matchesUseMediaQuery = useMediaQuery('(max-width:420px)')

  const effects = useRecoilValue<boolean|null>(effectsStateAtom)

  let isInIframe;

  try {
    isInIframe = window.self !== window.top;
  } catch (e) {
    isInIframe = true;
  }
  
  if (isInIframe) {

    
    if(anchor==='bottom' && window.location.hostname.includes('webapp')) {

      //console.log('fix telegram web version in iframe (telegram auto add main buttom)') 

      anchor='top'
    }
  }
  
  return (
      <>
        <SwipeableDrawer

         BackdropProps={{style:{backgroundColor:/* "rgba(0, 0, 0, 0.5)" */"transparent", opacity:1, backdropFilter: "blur(4px)"}}}
          
          {...(swipeAreaWidth ? { swipeAreaWidth } : {})}
          {...(container ? { container } : {})}

          disableSwipeToOpen={disableSwipeToOpen}

          disableBackdropTransition={true} // без этой опции лагает на слабых устройствах и в некоторых мобильных браузерах

          anchor={anchor}


          open={open || false}

          onClose={onClose}
          onOpen={onOpen}

          transitionDuration={{ appear: 400, enter: 400, exit: 400 }} // Добавление transitionDuration appear: 500, enter: 500, exit: 500 без этой опции лагает на слабых устройствах и в некоторых мобильных браузерах
          
          minFlingVelocity={250}
          
          {...(PaperProps ? { PaperProps } : {})}
          {...(ModalProps ? { ModalProps } : {})}
          {...(onClick ? { onClick } : {})}
          
          sx={ sx ? {...sx, "&" : {
              ...((effects || effects === null) ? {
              //backdropFilter: "blur(3px)", // лагает на слабых устройствах и в некоторых мобильных браузерах
                //backgroundColor: 'rgba(0, 0, 0, 5)',
              }:
              {
                backdropFilter: "unset"
              }
            ),
              width: "100%",
            },
           } : { 
            "&" : {
              ...((effects || effects === null) ? {
              //backdropFilter: "blur(3px)", // лагает на слабых устройствах и в некоторых мобильных браузерах
                //backgroundColor: 'rgba(0, 0, 0, 5)',
              }:
              {
                backdropFilter: "unset"
              }
            ),
              width: "100%",
            },
            '& .MuiPaper-root': {
              borderTopLeftRadius: container ? 15 : 0,
              borderTopRightRadius: container ? 15 : 0,
            },
          }}
          PaperProps={{
            style: {
              maxWidth: maxWidth ? !matchesUseMediaQuery ? 420 : 'unset' : 'unset',
              minHeight: minHeight,
              maxHeight: maxHeight,
  
              width: '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
              overflow: 'hidden',
            },
          }}
        >

          { container === null && anchor === 'bottom' && <Puller /> }
          
          { children }

          { container === null && anchor === 'top' && <PullerBottom /> }

        </SwipeableDrawer>
    </>
  )
}

export default CustomSwipeableDrawer
