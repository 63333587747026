
import { FC } from 'react'

import { useRecoilState } from 'recoil'

import { productSortStateAtom } from '@state/productSort'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'

import Sort from './Sort'

interface I {
  onChange?: () => void
}

const Filters: FC<I> = ({ onChange=()=>{} }) => {

  const [productSort, setProductSort] = useRecoilState<any>(productSortStateAtom)

  const onClose = () => {
    
    setProductSort((prevState:any) => ({ ...prevState, open: false }))
  } 

  const apply = () => {

    setProductSort((prevState:any) => ({ ...prevState, open: false }))
    onChange()
  }

  return <>

    <SwipeableDrawer
      anchor={'top'}
      open={productSort.open||false}
      onClose={onClose}
      onOpen={()=>{}}
      maxWidth
      sx={{ 
        zIndex: 1203,
        '&& .MuiPaper-root': {
          minHeight: '80px !important' ,//minHeight: '120px !important' ,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        },
      }}>

        <Container maxWidth="sm" sx={{ pt: 'calc(25px + env(safe-area-inset-top))', pb: 2, }}>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>

              <Grid xs={12}>
                <Sort/>
              </Grid>

              {/* <Grid xs={12}>
                <Button
                variant="contained"
                color={'secondary'}
                fullWidth
                sx={{
                  cursor: 'pointer', 
                  mb: 1.5,

                  bgcolor: 'primary.company',
                  color: 'white',
                  
                  backdropFilter: 'unset',
                  boxShadow: 'unset',
                  
                  "&:hover": {
                    bgcolor: 'primary.company',
                    opacity: 0.9,
                  },
                  "&:active": {
                    bgcolor: 'primary.company',
                    opacity: 0.9,
                  },

                }}
                onClick={()=>{
                  window?.navigator?.vibrate && window?.navigator?.vibrate(10);
                  apply()
                }
                }>

                  Применить

                </Button>
              </Grid> */}
            </Grid>
          </Box>

        </Container>

      </SwipeableDrawer>

  </>
}

export default Filters;