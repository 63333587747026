import { atom, selector } from "recoil"

export const categoriesStateAtom = atom({
  key: "categoriesStateAtom",
  default: [],
})

export const categoriesStateSelector = selector({
  key: "categoriesStateSelector",
  get: ({ get }) => {
    const categories = get(categoriesStateAtom)
    return categories
  }
})

