import { FC, useRef, useState, useEffect, useMemo } from 'react'

import qs from 'qs'

import Flicking, { ViewportSlot } from "@egjs/react-flicking";

import { AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";

import AnimateHeight from 'react-animate-height'

import { size } from 'lodash'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { shopStateAtom } from '@state/shop'
import { mallStateAtom } from '@state/mall'

import { callBackStateAtom } from '@state/callBack'
import { callBackButtonStateAtom } from '@state/callBackButton'
import { callBackAttachDataStateAtom } from '@state/callBackAttachData'

import { companyStateAtom } from '@state/company'
import { aiStateAtom } from '@state/ai'

import { bookmarksStateAtom } from '@state/bookmarks'

import { useCheckingProductBookmarks } from '@hooks/useCheckingProductBookmarks'

import SwipeableDrawer from '@components/SwipeableDrawer'

import Divider from '@mui/material/Divider';

import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal'

import ListItem from '@mui/material/ListItem'

import CircularProgress from '@mui/material/CircularProgress'
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';
import ShareIcon from '@mui/icons-material/Share';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotesIcon from '@mui/icons-material/Notes'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import useMeasure from '@hooks/useMeasure'

import useMobileDetect from '@hooks/useMobileDetect'

import Loading from '@components/Loading'

import api from '@services/api'

import { replaceImageFormat } from '@utils/replaceImageFormat'

import {getWeekDay, parseTimeToDate,convertTimeFormat} from '@utils/getWeekDay'

const  { REACT_APP_FAKE_AWS, REACT_APP_MINIO_URL_FROM, REACT_APP_MINIO_URL_TO } = process.env

declare var window: Window & typeof globalThis;

const Mall: FC = () => {

  const { windowSize } = useMeasure()

  const mobile = useMobileDetect()
  

  const [plugins, setPlugins] = useState<any>([]);

  useEffect(() => {
      setPlugins( [ new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false, }) ]);
  }, []);
  
  const width = 360
  const height = 540

  const flickingRef = useRef<Flicking>(null);


  const checkingProductBookmarks = useCheckingProductBookmarks()

  const [heightModal, setHeightModal] = useState<number>(380)

  
  const setShop = useSetRecoilState<any>(shopStateAtom)
  const [mall, setMall] = useRecoilState<any>(mallStateAtom)
  
  

  const [options, setOptions] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const [morePhotos, setMorePhotos] = useState<boolean>(false)
  
  const [bookmarks, setBookmarks] = useRecoilState<any>(bookmarksStateAtom)
  
  const ai = useRecoilValue<any>(aiStateAtom)
  const company = useRecoilValue<any>(companyStateAtom)

  const setCallBack = useSetRecoilState<boolean>(callBackStateAtom)  

  const [shareLot, setShareLot] = useState<boolean>(false)

  const [qrcode, setQrcode] = useState<boolean>(false)

  const setCallBackButton = useSetRecoilState<boolean>(callBackButtonStateAtom)
  const setCallBackAttachData = useSetRecoilState<any>(callBackAttachDataStateAtom)

  const [data, setData] = useState<any>(null)
  const [status, setStatus] = useState<any>(null)

  useEffect(() => {}, [bookmarks])

  useEffect(() => {

    if(!mall) return;
    const random = Math.random().toString(36)

    window.history.pushState({ product: random }, "mall", "?mall="+random);

  }, [mall])
  
  const onClose = () => {

    setHeightModal(380)

    setLoading(false)
    setMorePhotos(false)
    setOptions(false)
    setMall(null)
    setCallBackButton(false)
  }

  useEffect(() => {
    
    if(size(mall)>0){

      setLoading(true)

      const query = qs.stringify({
        filters: {
          shops: {
            show_on_map: {
              $eq: true
            }
          }
        },
        populate: {

          shops: {
            fields: ['id', 'name', 'description', 'show_on_map'],
            populate: {
              icon: {
                fields: ['url']
              }
            }
          },

          work_times: {
            populate: {
              work_times_day_of_week: true
            }
          },
          
          photo: {
            fields: ['url']
          },
          
          photos: {
            fields: ['url']
          },
        },
      }, {
        encodeValuesOnly: true, // prettify URL
      })

      api({ id: mall.id, collection: 'malls', query }).then((data) => {

        if(data){

          setCallBackAttachData(data.data)
          setData(data.data)
          setStatus(data.status)
          setCallBackButton(true)
        }
  
        setLoading(false)
  
      }).catch((error:any) => {
        setLoading(false)
      })
    }

  }, [mall])

  const ref = useRef<any>(null)

  const [openWorkTime, setOpenWorkTime] = useState(false);

  const workTime = (work_times_day_of_week:any) => {

    let open = false
    const day = getWeekDay()

    work_times_day_of_week?.map((item:any, index:number)=>{

      if(!item?.from || !item?.to) return;

      if(day === item?.day ){
        open = ((new Date().getTime() >= parseTimeToDate(item?.from).getTime()) && (new Date().getTime() <= parseTimeToDate(item?.to).getTime())) ? true : false 
      }
    })
      
    return open
  }
  
  return (
    <>

        <SwipeableDrawer
          anchor={'bottom'}
          open={size(mall)>0||false}
          onClose={onClose}
          onOpen={()=>{}}
          maxWidth
          sx={{
            zIndex: 1201,
            '& .MuiPaper-root': {
              "&" : {
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
              },
            },
          }}
          maxHeight={'90vh'}
          >

          <Container maxWidth="sm" sx={{maxHeight: '90vh', p: 0, overflowY: 'auto', overflowX: 'hidden' }}>
          
            { loading ?

              <Loading/>

              :

              <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': { display: 'none', },
                '&-ms-overflow-style:': { display: 'none', },
              }}
              >

                <Box sx={{ 
                  width: '100vw',
                  maxWidth: 420,
                  overflow: 'hidden',
                }}>
                    
                  <Typography variant="h6" gutterBottom align="center" sx={{ mt: 3, width: '100%' }}>

                    {data?.name}

                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{
                      p: 2,
                      maxHeight:"calc(90vh - 75px)",

                      overflow: 'scroll',

                      scrollbarWidth: "none",
                      '&::-webkit-scrollbar': {display: 'none',
                      },
                      '&-ms-overflow-style:': {display: 'none',
                      },
                    }}
                  >

                    <Box
                    sx={{
                      width: '100%',
                      p:2,
                      pt: 0,
                      borderRadius: 2,
                    }}>

                      { ( data?.photos && data?.photos?.length > 0 ) && 

                        <Box sx={{ borderRadius: 3, overflowX: "hidden" }}>
                          <Flicking ref={flickingRef} plugins={plugins} circular={true}>
                              {data?.photos?.map((item:any) => (
                                  <Box
                                  className="card-panel"
                                  sx={{
                                    backgroundImage:`url(${item?.url})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    width: "100%",
                                    height: 200,
                                  }}
                                  />
                              ))}
                          </Flicking>
                        </Box>
                      }

                      <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: data?.description}} 
                      sx={{
                        '& p': {
                          margin: 0,
                          padding: 0,
                        },
                        p: 2,
                      }}/>

                      <Box
                          height="100%"
                          width="100%"
                          p={0}
                        >

                        <Divider sx={{ mt: 0, mb: 1 }}/>

                        { data?.address && 
                          <>
                            
                            <Typography variant="body2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: data?.address}} sx={{
                            mt:-0.7,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            '& p': {margin: 0,padding: 0,},}}/>
                            <Divider sx={{ mt: 1, mb: 1 }}/>
                          </>
                        }
                        
                        { ( data?.work_times && data?.work_times?.work_times_day_of_week?.length > 0  ) &&

                          <>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              justifyContent="space-between"
                              width="100%"
                              onClick={()=>{
                                setOpenWorkTime(!openWorkTime)
                              }}>
                              <Grid container spacing={0}>
                                
                                <Grid xs={5}>
                                  <Typography variant="body1" align="left"  dangerouslySetInnerHTML={{__html: 'Bремя работы:'}} sx={{color: 'black', whiteSpace: 'nowrap',}}/> 
                                </Grid>
                                <Grid xs={7}>
                                  <Typography variant="body1" align="left"  sx={{color: workTime(data?.work_times?.work_times_day_of_week) ? 'green' : 'rgb(198, 0, 0)', pl: 2, mr: 2, mb: 1 }}> 
                                    {workTime(data?.work_times?.work_times_day_of_week) ? <>Сейчас открыто</> : <>Сейчас закрыто</>}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Box>
                                { openWorkTime ? <ExpandLess /> : <ExpandMore />}
                              </Box>
                            </Box>
                            
                            <AnimateHeight
                            duration={500}
                            height={openWorkTime ? 'auto':0}
                            >
                              <Grid container spacing={0}>

                                {data?.work_times?.work_times_day_of_week?.map((item:any, index:number)=>{

                                if(!item?.work){ return (
                                  <>
                                    <Grid xs={4}>
                                      <Typography key={index} variant="body2" align="left" sx={{color: 'black', }}>{item?.day}</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                      <Typography key={index} variant="body2" align="left" sx={{color: 'rgb(198, 0, 0)', pl: 2, }}>выходной</Typography>
                                    </Grid>
                                  </>
                                  )}
                                  return (
                                  <>
                                    <Grid xs={4}>
                                      <Typography key={index} variant="body2" align="left" sx={{color: 'black', }}>{item?.day}</Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                      <Typography key={index} variant="body2" align="left" sx={{color: getWeekDay() /* 'Пятница'  */=== item?.day ?  

                                      new Date().getTime() >= parseTimeToDate(item?.from).getTime() ? ( new Date().getTime() <= parseTimeToDate(item?.to).getTime() ? 'green' : 'black' )  
                                      :'black'
                                      : 'black', pl: 2 }}>{convertTimeFormat(item?.from)} – {convertTimeFormat(item?.to)}</Typography>
                                    </Grid>
                                  </>
                                  )
                                })}
                              
                              </Grid>
                            </AnimateHeight>

                            <Divider sx={{ mt: 1, mb: 1 }}/>
                          </>
                          }

                        </Box>
                        
                    </Box>
                      
                    {data?.shops?.map((shop:any, index:number) => {

                      if(!shop?.show_on_map) return;

                      return (
                      <Box key={index}
                      sx={{
                        width: '100%',
                        p:2,
                        borderRadius: 2,
                      }}
                      onClick={()=>{
                        setShop(shop)
                      }}>

                        <Grid container spacing={3}>
                        <Grid xs={3}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              height="100%"
                              width="100%"
                            >
                              <Box
                                sx={{ 
                                  backgroundImage:`url(${shop?.icon?.url})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  height: 64,
                                  width: 64,
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid xs={9}>
                            <Box>

                              <Typography variant="subtitle1" gutterBottom align="left">

                                {shop?.name}

                              </Typography>

                              <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: shop?.description}} sx={{
                                '& p': {
                                  margin: 0,
                                  padding: 0,
                                },
                              }}/>
                              
                            </Box>
                          </Grid>
                        </Grid>

                      </Box>)
                    })}
              
                  </Box>
                        
                </Box>

              </Box>

            }

          </Container>

        </SwipeableDrawer>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={shareLot||false}
          autoHideDuration={2500}
          onClose={()=>{setShareLot(!shareLot)}}
          message="Ссылка скопирована"
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2,
              zIndex: 10000,
            },
            '& .MuiSnackbarContent-message ': {
              display: 'block',
              textAlign: "center",
              width: '100%',
            },
          }}
        />
        
    </>
  )
}

export default Mall
