
import { FC, useEffect, useState, useRef, useMemo, Fragment } from 'react'



import qs from 'qs'

import Flicking from "@egjs/react-flicking";

import { AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";

import { size } from 'lodash'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { categoriesStateAtom } from '@state/categories'
import { categoryCatalogStateAtom } from '@state/categoryCatalog'

import { selectCategoryForAddProductStateAtom } from '@state/selectCategoryForAddProduct'

import { productStateAtom } from '@state/product'
import { productSortStateAtom } from '@state/productSort'

import { basketStateAtom } from '@state/basket'
import { pageStateAtom } from '@state/page'

import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'

import Button from '@mui/material/Button'

import TextField from '@mui/material/TextField'
import TuneIcon from '@mui/icons-material/Tune';

import Typography from '@mui/material/Typography';

import { useForm, Controller } from 'react-hook-form'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import CardMedia from '@mui/material/CardMedia';

import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

/* import SearchIcon from '@mui/icons-material/Search'; */
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import useMeasure from '@hooks/useMeasure';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Draggable from '@components/Draggable';

import api from '@services/api';

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
});





















const ProductItem: FC<any> = ({ product }) => {

  const setOpenProductDetails = useSetRecoilState<any>(productStateAtom);
  
  const [basket, setBasket] = useRecoilState<any[]>(basketStateAtom)

  const handleCheckedBasket = () => {

    for(const item of basket) {

      if(item?.id === product?.id) return true;
    }
    return false
  };
  
  const handleAddBasket = () => {

    setBasket([...basket, product]);
  };

  const handleRemoveBasket = () => {

    setBasket(basket.filter((item) => item?.id !== product?.id));
  };

  return (
    <Grid xs={6}>
      <Box sx={{m: 0, mt: 2,mb: 2}}>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              height="100%"
              width="100%"

              onClick={()=>{
                setOpenProductDetails(product)
              }}
            >
              <Box
                sx={{ 
                  backgroundImage:`url(${product?.photo?.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: 280,
                  width: '100%',
                  borderRadius: 5,
                }}
              />
            </Box>

            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
              sx={{
                m: 1,
                height: '100%',
              }}>

              <Box>

                <Typography variant="h6" align="left"  component="span"  dangerouslySetInnerHTML={{__html: product?.name}} sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                '& p': {margin: 0,padding: 0,},}}
                onClick={()=>{
                  setOpenProductDetails(product)
                }}/>

                <Typography variant="subtitle1" align="left"  component="span"  sx={{'& p': {margin: 0,padding: 0,}, transform: 'scale(1.2)'}}
                onClick={()=>{
                  setOpenProductDetails(product)
                }}
                >{product?.price}₽</Typography>


                <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: product?.description}} sx={{
                  mt: 1,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  '& p': {margin: 0,padding: 0,},}}/>

                <Button sx={{mt: 1, height: 36, width: '100%', color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }} onClick={handleCheckedBasket() ? handleRemoveBasket : handleAddBasket}>
                  { handleCheckedBasket() ? "Удалить" : "В корзину"}
                </Button>
                  
              </Box>

            </Stack>

        </Box>

      </Grid>
  )
};

interface ChildProps {
  child: any;
};

const ChildItem: FC<ChildProps> = ({ child }) => {

  const setSelectCategoryForAddProduct = useSetRecoilState<any>(selectCategoryForAddProductStateAtom);
  const setPage = useSetRecoilState<any>(pageStateAtom);

  const [open, setOpen] = useState(false);

  const handleClick = () => {

    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick} sx={{ pl: 0, pr: 0 }}>
        <ListItemText primary={child?.name} />
        {child?.childrens?.length > 0 ? open ? <ExpandLess sx={{mr: 2}} /> : <ExpandMore sx={{mr: 1}} /> : 
        <Button sx={{ mr: 1,height: 36, width: 120, minWidth: 120, color: 'rgb(98, 100, 101)', fontSize: 14, border: '1px solid #e0e0e0', borderRadius: 3 }}
        onClick={() => {
          
          setSelectCategoryForAddProduct(child)
          setPage('addProduct')
        }}>
          Выбрать
        </Button>}
      </ListItem>
      {child?.childrens?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding  sx={{ pl: 2 }}>
            {child?.childrens.map((child:any) => (
              <ChildItem key={child?.id} child={child} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

interface ParentProps {
  parent: any;
}

const ParentItem: FC<ParentProps> = ({ parent }) => {

  const [open, setOpen] = useState(false);

  const [ category, setCategory] = useRecoilState<any>(categoryCatalogStateAtom);
 
  const handleClick = () => {

    setOpen(!open);

    if(parent?.childrens.length === 0) return setCategory([...category, parent]);
  };

  return (
    <Box sx={{border: '1px solid #e0e0e0', borderRadius: 3, m: 1, mt: 2}}>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={parent.name} />
        {parent?.childrens.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>

      {parent?.childrens.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 2 }}>
            {parent?.childrens.map((child:any) => (
              <ChildItem key={child.id} child={child} />
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
};


const HierarchicalList: FC<{ data: any }> = ({ data }) => {
  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        mt: 0,
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': { display: 'none' },
        '&::-ms-overflow-style': { display: 'none' },
      }}
      component="nav"
    >
      {data?.map((item: any, index: number) => (
        <Fragment key={index}>
          <ParentItem parent={item} />
        </Fragment>
      ))}
    </List>
  );
};

const SelectCategoryForAddProduct: FC<any> = () => {

  const {windowSize} = useMeasure();

  const [plugins, setPlugins] = useState<any>([]);

  useEffect(() => {
      setPlugins( [ new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false, }) ]);
  }, []);
  
  const width = 360 > (windowSize.width - 60) ? ( windowSize.width - 60 ) : 360 //360;


  const height = 540;

  const flickingRef = useRef<Flicking>(null);

  const categories = useRecoilValue<any>(categoriesStateAtom);

  const [category, setCategory] = useRecoilState<any>(categoryCatalogStateAtom);

  const [products, setProducts] = useState<any>([]);



  const [openFormAddProduct, setOpenFormAddProduct] = useState<any>({});



  const [productSort, setProductSort] = useRecoilState<any>(productSortStateAtom);

  //const [loading, setLoading] = useState<boolean>(false);


  
  const [basket, setBasket] = useRecoilState<any[]>(basketStateAtom)

  const handleCheckedBasket = (product:any) => {

    for(const item of basket) {

      if(item?.id === product?.id) return true;
    }
    return false
  };
  
  const handleAddBasket = (product:any) => {

    setBasket([...basket, product]);
  };

  const handleRemoveBasket = (product:any) => {

    setBasket(basket.filter((item) => item?.id !== product?.id));
  };


  const searchFormTextFieldRef = useRef<any>(null);

  const [search, setSearch] = useState('');

  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  });

  useEffect(() => {

    if(search?.length < 2) return;

    const query = qs.stringify({
      randomSort: false,
      filters: {
        category: {
          id: category?.id
        },
        ...(search !== null && search?.length > 0 && {
          '$or': [
            {
              name: {
                $containsi: search
              }
            },
            {
              description: {
                $containsi: search
              }
            },
            {
              sku: {
                $containsi: search
              }
            },
          ],
        }),
      },
      populate: {
        shop: {
          fields: ["name", "description", "coords", "address"],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
        video: {
          fields: ['url']
        },
        photo: {
          fields: ['url']
        },
        photos: {
          fields: ['url']
        },
      },
      fields: ['name', 'description', 'price', 'stock', 'sku'],
      locale: ['ru'],
    }, {
      encodeValuesOnly: true, // prettify URL
    })

    api({ collection: 'products', query }).then((data) => {

      if(data?.data) setProducts(data?.data);
    })
  
  }, [search]);

  useEffect(() => {

    if(category[category?.length - 1]?.childrens?.length === 0){

      const query = qs.stringify({
        randomSort: false,
        filters: {
          category: {
            id: category[category?.length - 1]?.id
          }
        },
        populate: {
          shop: {
            fields: ["name", "description", "coords", "address"],
            populate: {
              icon: {
                fields: ["url"],
              }
            }
          },
          video: {
            fields: ['url']
          },
          photo: {
            fields: ['url']
          },
          photos: {
            fields: ['url']
          },
        },
        fields: ['name', 'description', 'price', 'stock', 'sku'],
        locale: ['ru'],
        sort: [productSort.value.sort],
      }, {
        encodeValuesOnly: true,
      })

      api({ collection: 'products', query }).then((data:any) => {

        if(data?.data) setProducts(data?.data);
  
      }).catch((error:any) => {
        
        console.log(error);
      })
    }
  }, [category, productSort]);



  const handleBack = () => {

    if(category.length === 1) return setCategory([]);
    if(category.length > 1) {
        
      const newB = [...category].shift(); 

      const arr = [];
      arr.push(newB);

      return setCategory(arr);
    };

    return setCategory([]);
  };

  return (
    <>
      <Container  sx={{ pt:1, pb: 5, pl: /*windowSize.width < 375 ? 1 :*/ 3, pr: /*windowSize.width < 375 ? 1 :*/ 3, overflow: 'hidden', }}>

        <HierarchicalList data={categories}/>
        
      </Container>

      <Draggable open={( ( category && category.length > 0 ) ) || false} setOpen={handleBack}>
        
        <Box
        sx={{
          p: 3,
          pt: 1,
          pb: 1,
          userSelect: 'none',
          overflow: 'hidden',
          height: 'calc(100vh - 60px)',
          width: '100%',
          scrollbarWidth: "none",
          position: 'absolute',
          '&::-webkit-scrollbar': { display: 'none', },
          '&-ms-overflow-style:': { display: 'none', },
        }}>
              
          <Box
          sx={{
            userSelect: 'none',
            overflow: 'auto',
            height: 'calc(100vh - 60px)',
            width: '100%',
            scrollbarWidth: "none",
            '&::-webkit-scrollbar': { display: 'none', },
            '&-ms-overflow-style:': { display: 'none', },
          }}>

            <HierarchicalList data={categories}/>
        
          </Box>
      
        </Box>
      
      </Draggable>

      <Draggable open={size(openFormAddProduct)>0||false} setOpen={()=>{setOpenFormAddProduct(null);}}>
        
        <Box 
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
          mt: 0,
          pt: 3,
          top: 0,
        }}>
        
          <Grid container spacing={3}>
            
            <Grid xs={3}>

              <ArrowBackIosNewIcon sx={{ cursor: 'pointer', width: 48, ml: 2 }} onClick={() => { setOpenFormAddProduct(null); }}/>

            </Grid>

            {/* <Grid xs={9}>
        
              <Typography variant="h6" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.name}} sx={{
              mt: -0.5,
              ml: 2,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              '& p': {margin: 0,padding: 0,},}}/>
        
            </Grid> */}

          </Grid>
        
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            mt: 2,
            p: 3,
            pt: 1,
            pb: 1,
            userSelect: 'none',
            overflow: 'auto',
            height: "calc(100vh - 60px)",
            width: '100vw',
            scrollbarWidth: "none",
            '&::-webkit-scrollbar': { display: 'none', },
            '&-ms-overflow-style:': { display: 'none', },
          }}>

            Product

          </Box>
        
        </Box>
        
      </Draggable>
                                  
    </>
  )
};

export default SelectCategoryForAddProduct;
