import { FC, useRef, useState, useEffect } from 'react';

import qs from 'qs'

import { useSetRecoilState } from 'recoil'
import { shopStateAtom } from '@state/shop'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Flicking, { ViewportSlot } from "@egjs/react-flicking";

import { AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";

/* import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css"; */

import Api from "@services/api";

const Ads: FC<any> = () => {

  const [data, setData] = useState<any[]>([])
  
  const setShop = useSetRecoilState(shopStateAtom)

  const [plugins, setPlugins] = useState<any>([]);

  useEffect(() => {
      setPlugins( [ new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false, }) ]);
  }, []);
  
  const width = '90vw'
  const height = '200px'

  const flickingRef = useRef<Flicking>(null);
  const [isLastSlide, setIsLastSlide] = useState(false);

  useEffect(() => {
  
    const query = qs.stringify({
      randomSort: false,
      filters: {
        active: true,
      },
      populate: {
        open: true,
        shop: {
          fields: ["name", "description", "coords"],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
        image: {
          fields: ["url"],
        },
      },
      pagination: {
        pageSize: 15,
        page: 1,
      },
      sort: "createdAt:ASC",
      locale: ['ru'],
    }, {
      encodeValuesOnly: true,
    })

    Api({ collection: 'ads', query }).then((data:any) => {

      if(data?.data) return setData([...data?.data]);
      
    }).catch((error:any) => {

      console.log(error)
    })
  }, [])

  return (
    <>
      <Box sx={{ borderRadius: 3, overflowX: "hidden" }}>
        <Flicking ref={flickingRef} plugins={plugins} circular={true}>
            {data?.map((item:any, index:number) => (
                <Box
                key={index}
                className="card-panel"
                sx={{
                  backgroundImage:`url(${item?.image?.url})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  width,
                  height,
                }}
                onClick={()=>{

                  if(item?.open === 'link') return window.open(item?.link, '_blank');
                
                  setShop({ ...item?.shop })
                }}
                />
            ))}
        </Flicking>
      </Box>
  </>);
};


export default Ads