
const getWeekDay = () => {
  let days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

  return days[new Date().getDay()];
}

const parseTimeToDate = (time:any) => {

  const [hours, minutes] = time.split(':').map(Number);
  const now = new Date();
  now.setHours(hours, minutes, 0, 0);
  
  return now;
};

const convertTimeFormat = (time:any) => {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

export default getWeekDay
export { getWeekDay, parseTimeToDate, convertTimeFormat };
