
import { FC } from 'react'

import { useRecoilState } from 'recoil'

import { productSortStateAtom } from '@state/productSort'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  customCursor: {
    cursor: 'pointer',
  },
})

interface Option {
  id: number;
  type: string;
  sort: string;
  name: string;
}

const options: Option[] = [
  { id: 5, type: 'default', sort: 'id:ASC', name: 'по умолчанию' },
  { id: 6, type: 'price', sort: 'price:ASC', name: 'сначала недорогие' },
  { id: 7, type: 'price', sort: 'price:DESC', name: 'сначала дорогие' },
  { id: 8, type: 'new', sort: 'id:ASC', name: 'сначала новые' },
  { id: 9, type: 'new', sort: 'id:DESC', name: 'сначала старые' },
]

const Sort: FC = () => {

  const [productSort, setProductSort] = useRecoilState<any>(productSortStateAtom)

  const classes = useStyles()

  return (
    <Autocomplete
      value={productSort.value}
      onChange={(event, newValue) => {
        window?.navigator?.vibrate && window?.navigator?.vibrate(10);
        if(!newValue) setProductSort((prevState:any) => ({ ...prevState, open: false, value: { type: 'default', sort: 'id:DESC', name: 'по умолчанию' } }))
        setProductSort((prevState:any) => ({ ...prevState, open: false, value: { type: newValue.type, sort: newValue.sort, name: newValue.name} }));
      }}
      options={options}
      isOptionEqualToValue={(option, value) => option?.name?.id === value?.name?.id}
      getOptionLabel={(option) => option.name}
      noOptionsText={'Нету донных'}
      renderInput={(params) => (
        <TextField
          onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
          sx={{
            userSelect: 'none',
            justifyContent: `center`,
            alignItems: `center`,
            '& .MuiInputBase-root': {
              cursor: 'pointer', 
            },
            '& .MuiInputBase-input': {
              cursor: 'pointer', 
            },
          }}
          {...params}
          label="Сортировка"
          variant="outlined"
          fullWidth
          inputProps={{
            ...params.inputProps,
            readOnly: true,
            className:  classes.customCursor, // Применяем пользовательский класс CSS при не выбранной опции
          }}
          InputLabelProps={{sx: {  marginTop: -0.25, userSelect: 'none', } }}
        />
      )}
    />
  )
}

export default Sort;
