import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const globalProgressBackdropTextStateAtom = atom({
  key: "globalProgressBackdropTextStateAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],

})

export const globalProgressBackdropTextStateSelector = selector({
  key: "globalProgressBackdropTextStateSelector",
  get: ({ get }) => {
    const globalProgressBackdropText = get(globalProgressBackdropTextStateAtom)
    return globalProgressBackdropText
  }
})

