import { FC, useRef, useMemo, useEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'

import { shopStateAtom } from '@state/shop'

import Container from '@mui/material/Container';

import Grid from '@mui/material/Unstable_Grid2'

import Avatar from '@mui/material/Avatar'

import SendIcon from '@mui/icons-material/Send'
import PersonIcon from '@mui/icons-material/Person'


import { chatBotStateAtom } from '@state/chatBot'
import { chatBotMessagesStateAtom } from '@state/chatBotMessages'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import usePlatformDetect from '@hooks/usePlatformDetect'

import api from '@services/api'

import { useForm, Controller } from 'react-hook-form'

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';


import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
//import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SwipeableDrawer from '@components/SwipeableDrawer'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"

import useMobileDetect from '@hooks/useMobileDetect'

import Api from '@services/api'

const drawerBleeding = 82;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
})

const Search: FC = (props:any) => {

  const { window } = props;

  const mobile = useMobileDetect()

  const setShop = useSetRecoilState<any>(shopStateAtom)

  const refContainer = useRef<any>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const [data, setData] = useState<any[]>([])

  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState('')

  const filteredData = useMemo(() => {
    return data.filter((item:any) =>
      typeof item.name === 'string' && item.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [data, search])


  const searchFormTextFieldRef = useRef<any>(null)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    if(!newOpen) searchFormTextFieldRef?.current?.blur();
  };

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;
  
  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  })


  useEffect(() => {
  
    const query = qs.stringify({
      randomSort: false,
      filters: {
        show_on_map: true,
        show_on_map_before: {
          $gte: new Date().getTime()
        },
      },
      
      populate: {
        malls: {
          fields: ["name", 'description'],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
        icon: {
          fields: ["url"],
        }
      },
      pagination: {
        pageSize: 500,
        page: 1,
      },
      sort: "createdAt:DESC",
      locale: ['ru'],
      group: false,
    }, {
      encodeValuesOnly: true,
    })

    Api({ collection: 'shops', query }).then((data:any) => {

      setLoading(false)

      if(data?.data) return setData(data.data);

    }).catch((error:any) => {

      console.log(`data `)
      console.log(error)

      setLoading(false)
    })

  }, [])

  

  

  const [ContainerFullHeight, setContainerFullHeight] = useState<boolean>(false)

  useEffect(() => {

    const windowHeight = window?.innerHeight ? window?.innerHeight : 0;
    const containerHeight = refContainer?.current?.innerHeight ? refContainer?.current?.innerHeight : 0;

    setContainerFullHeight( ( containerHeight > windowHeight * 0.9 ) ? true : false)

  }, [refContainer?.current?.innerHeight, search])

  useEffect(() => {
    console.log(`ContainerFullHeight: ${ContainerFullHeight}`)
  }, [ContainerFullHeight])
  




  return (

    <Root>

      <ButtonGroup
      orientation="vertical"
      aria-label="Vertical button group"
      variant="contained"
      sx={{
        position: 'absolute', top: 'calc(50vh - 140px)', right: 12,
        backgroundColor: 'white',
        borderRadius: 3.5,
        width: 45,
        height: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={toggleDrawer(true)}>

        <SearchIcon fontSize='medium'/>

      </ButtonGroup>

      <CssBaseline />
{/*       <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      /> */}
      
      <SwipeableDrawer
      //container={container}
      //anchor={ mobile ? "bottom" : "left"}
      anchor={"bottom"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      /* swipeAreaWidth={drawerBleeding} */
      disableSwipeToOpen={true}
      /* ModalProps={{
        keepMounted: true,
      }} */
      onClick={(e:any)=>{
        if(!open){
          setOpen(true);
          searchFormTextFieldRef.current.focus();
        }
      }}
      minHeight={'100vh'}
      >

        <Container ref={refContainer} maxWidth="sm" sx={{pt:/* 1 */ ContainerFullHeight ? 1 : 'calc(10px + env(safe-area-inset-top))', pb: /* 5, */ ContainerFullHeight ? 5 : 'calc(10px + env(safe-area-inset-bottom))', pl: 3, pr: 3, overflow: 'auto'}}>

          <Controller
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, isDirty, error },
            }) => (
              <TextField
                fullWidth
                size="small"
                margin="normal"
                variant="outlined"
                //label="Поиск магазина"

                onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                autoFocus={false}
                placeholder="Поиск магазина"

                value={search}
                
                onBlur={onBlur}
                inputRef={(input) => {

                  ref(input);
                  if (input) searchFormTextFieldRef.current = input;
                }}
                onChange={(e:any)=>{

                  setSearch(e.target.value);
                }}
                onFocus={(e:any)=>{

                  setOpen(!open)
                }}
                InputProps={{
                  endAdornment: search !== '' && (
                    
                    <InputAdornment position="end" sx={{ marginRight: 1 }}>
        
                      <IconButton onClick={()=>{
                        toggleDrawer(!toggleDrawer)
                        setSearch('');
                        }} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            name="name"
            control={control}
            rules={{ required: true }}
          />

          { search.length > 0 && filteredData.length === 0 &&
            
            <>

              <Box 
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                overflow: 'hidden',
                width: '100%',
                height: "80vh",
              }}>

                <ProductionQuantityLimitsIcon fontSize='large' sx={{transform: 'scale(3)'}}/>

                <Typography variant="h6" align="left"  component="span" sx={{mt: 5}}>

                  Ничего не найдено!

                </Typography>

              </Box>

            </>

          }

          {filteredData?.map((shop:any, index:number) => {

            return (
            <Box key={index}
            sx={{
              width: '100%',
              p:2,
              mb: 2,
              //border: '1px solid #909090',
              borderRadius: 2,
            }}
            onClick={()=>{
              setShop(shop)
            }}>

              <Grid container spacing={3}>
              <Grid xs={3}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                  >
                    <Box
                      sx={{ 
                        backgroundImage:`url(${shop?.icon?.url})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: 64,
                        width: 64,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={9}>
                  <Box>

                    <Typography variant="subtitle1" gutterBottom align="left">

                      {shop?.name}

                    </Typography>

                    <Typography variant="subtitle2" align="left"  component="span"  dangerouslySetInnerHTML={{__html: shop?.description}} sx={{
                      '& p': {
                        margin: 0,
                        padding: 0,
                      },
                    }}/>
                    
                  </Box>
                </Grid>
              </Grid>

            </Box>)
          })}

        </Container>

      </SwipeableDrawer>

    </Root>
  )
}

export default Search
