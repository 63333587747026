import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userDataStateAtom = atom({
  key: "userDataStateAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const userDataStateSelector = selector({
  key: "userDataStateSelector",
  get: ({ get }) => {
    const userData = get(userDataStateAtom)
    return userData
  }
})

