import { FC } from 'react'

import { useRecoilState } from 'recoil'

import { basketStateAtom } from '@state/basket'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';

import useMeasure from '@hooks/useMeasure';

const Basket: FC = () => {

  //const {windowSize} = useMeasure();

  const [data, setData] = useRecoilState<any[]>(basketStateAtom)

  const handleDeleteProduct = ({id}: {id: number}) => {

    setData(data.filter((item: any) => item.id !== id))
  }

  if(data?.length === 0) {
    
    return (
      <Container  sx={{ pt:1, pb: 5, pl: /*windowSize.width < 375 ? 1 :*/ 3, pr: /*windowSize.width < 375 ? 1 :*/ 3, overflow: 'hidden', height: "100vh", }}>

        {/* <Typography variant="h5"  sx={{ p: 1, width: '100%', textAlign: 'center', }}>

          Корзина

        </Typography> */}

        <Box 
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          overflow: 'hidden',
          width: '100%',
          height: "calc(100vh - 60px)",
        }}>

          <ShoppingCartIcon fontSize='large' sx={{transform: 'scale(3)'}}/>

          <Typography variant="h6" sx={{mt: 5}}>

            ОЙ! А тут пусто...

          </Typography>

        </Box>

      </Container>
    )
  }
  
  return (
    
    <Container  sx={{ pt:/* 1 */'calc(10px + env(safe-area-inset-top))', pb: 5, pl: /*windowSize.width < 375 ? 1 :*/ 3, pr: /*windowSize.width < 375 ? 1 :*/ 3, overflow: 'hidden', }}>

      {/* <Typography variant="h5" sx={{ p: 1,  textAlign: 'center', }}>

        Корзина

      </Typography>
 */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', height: 'calc(100vh - 70px)', overflow: 'auto',
      scrollbarWidth: "none",
      '&::-webkit-scrollbar': { display: 'none', },
      '&-ms-overflow-style:': { display: 'none', }, }}>

        {data?.map((openProductDetails: any, index: number) => {

const firstImage = openProductDetails?.files?.find((item: any) => item?.mime.includes("image"));


return (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', mt: 3, width: '100%', }}>
         
            <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            height="100%"
            width={120}>
            <Box
              sx={{ 
                backgroundImage:`url(${firstImage?.url})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: 200,
                minWidth: 120,
                borderRadius: 5,
              }}
            />
            </Box>

            <Stack
            position="relative"
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              m: 1,
              mt: 0,
              ml: 2,
              width: '100%',
            }}>
      
              <Box sx={{position: 'relative', width: '100%'}}>

                <Typography variant="h6" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.name}} sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                '& p': {margin: 0,padding: 0,},}}/>

                <Typography variant="subtitle1" align="left"  component="span"  sx={{ /* pl: 2, */'& p': {margin: 0,padding: 0,}, transform: 'scale(1.2)'}}>{openProductDetails?.price}₽</Typography>

                <Box>
                  
                  <Typography variant="caption" align="left"  component="span"  sx={{'& p': {margin: 0,padding: 0,},}}>Арт</Typography>
                  <Typography variant="caption" align="left"  component="span"  dangerouslySetInnerHTML={{__html: openProductDetails?.sku}} sx={{ pl: 1, '& p': {margin: 0,padding: 0},}}/>

                </Box>
      
                <Typography variant="subtitle1" align="left"  component="span"  dangerouslySetInnerHTML={{__html: `Магазин: ${openProductDetails?.shop?.name}`}} sx={{
                  mt: 1,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  '& p': {margin: 0,padding: 0,},}}/>

                <Typography variant="subtitle1" align="left"  component="span"  dangerouslySetInnerHTML={{__html: `${openProductDetails?.shop?.address}`}} sx={{
                  mt: 1,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  '& p': {margin: 0,padding: 0,},}}/>

              </Box>

              <Button title="Удалить" variant="contained" onClick={()=>{ handleDeleteProduct({id: openProductDetails.id}) }} sx={{
                position: 'absolute', top: -5, right: 0, p: 0, minWidth: 25, height: 25, cursor: 'pointer',
              }}>
                
                <ClearIcon/>
              </Button>

            </Stack>

          </Box>
        )
})}

      </Box>

    </Container>

  )
}

export default Basket
