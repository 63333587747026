import { atom, selector } from "recoil"

export const shopStateAtom = atom({
  key: "shopStateAtom",
  default: null,
})

export const shopStateSelector = selector({
  key: "shopStateSelector",
  get: ({ get }) => {
    const shop = get(shopStateAtom)
    return shop
  }
})

