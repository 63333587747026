import { atom, selector } from "recoil"
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const authPhoneStateAtom = atom({
  key: "authPhoneStateAtom",
  default: true,
  effects_UNSTABLE: [persistAtom],

})

export const authPhoneStateSelector = selector({
  key: "authPhoneStateSelector",
  get: ({ get }) => {
    const authPhone = get(authPhoneStateAtom)
    return authPhone
  }
})

