
import { FC, useEffect, useState, useRef } from 'react'

import axios from 'axios'
import qs from 'qs'

import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import { jwtStateAtom } from '@state/jwt'
import { userDataStateAtom } from '@state/userData'

import { shopStateAtom } from '@state/shop'
import { pageStateAtom } from '@state/page'
import { productSortStateAtom } from '@state/productSort'

import { catalogShopProductEditStateAtom } from '@state/catalogShopProductEdit'
import { productEditStateAtom } from '@state/productEdit'


import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import Badge from '@mui/material/Badge';

import Grid from '@mui/material/Unstable_Grid2'
import Snackbar from '@mui/material/Snackbar';

import TextField from '@mui/material/TextField'

import CircularProgress from '@mui/material/CircularProgress'

import TuneIcon from '@mui/icons-material/Tune';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import NearMeIcon from '@mui/icons-material/NearMe';

import styles from './styles.module.scss'

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import api from '@services/api'

import { useForm, Controller } from 'react-hook-form'

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import BackupIcon from '@mui/icons-material/Backup';


import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"



const Schema = yup.object().shape({
  name: yup.string().required("Необходимо ввести ключ"),
})





const Test: FC<any> = ({ evtId }) => {

  const [jwt] = useRecoilValue(jwtStateAtom)

  const [userData, setUserData] = useRecoilState<any>(userDataStateAtom)
  
  const [selectedFiles, setSelectedFiles] = useState<any>(undefined);
  const [imagePreviews, setImagePreviews] = useState<any>([]);

  const [image, setImage] = useState<any>(null);

  const handleSubmitFile = async (e:any) => {

    e.preventDefault();

    const formData = new FormData();
    formData.append('files', image);
    formData.append('ref', 'plugin::users-permissions.user');
    formData.append('refId', evtId);
    formData.append('field', 'avatar');
    
    try {

      const uploadResponse = await axios.post('/api/upload/', formData, {
        headers: {
          'Authorization': `Bearer ${jwt}` 
        }
      });

      const uploadData = uploadResponse.data[0];

      setUserData({
        ...userData,
        avatar: {
          url: uploadData.url
        }
      })

      setImage(null);
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFileChange = (e:any) => {

    setImage(e.target.files[0]);

    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    setSelectedFiles(e.target.files);
    setImagePreviews(images);
  };

  return (
    <div>
      <form onSubmit={handleSubmitFile}>
        <div>
          <input type='file' onChange={handleFileChange} multiple/>
        </div>
        <br />
        <br />
        <input type='submit' value='Upload' className='btn' />
      </form>

      {/*
        {imagePreviews && (
          <div>
            {imagePreviews.map((img:any, i:any) => {
              return (
                <img className="preview" src={img} alt={"image-" + i} key={i} />
              );
            })}
          </div>
        )}
      */}

    </div>
  );
}

const Account: FC<any> = () => {

  const [userName, setUserName] = useState<any>(null)
  const [userNameNotFree, setUserNameNotFree] = useState<boolean>(true)

  const resetProductSort = useResetRecoilState(productSortStateAtom)

  const [data, setData] = useRecoilState<any>(userDataStateAtom)

  
  const setCatalogShopProductEdit = useSetRecoilState<boolean>(catalogShopProductEditStateAtom)
  const setProductEdit = useSetRecoilState<any>(productEditStateAtom)


  const setShop = useSetRecoilState(shopStateAtom)
  const setPage = useSetRecoilState(pageStateAtom)

  const [loading, setLoading] = useState<boolean>(false)

  const [jwt, setJwt] = useRecoilState(jwtStateAtom)

  /* avatar start */

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const [userData, setUserData] = useRecoilState<any>(userDataStateAtom)
  
  const [selectedFiles, setSelectedFiles] = useState<any>(undefined);
  const [imagePreviews, setImagePreviews] = useState<any>([]);

  const [image, setImage] = useState<any>(null);

  const handleSubmitFile = async () => {

    const formData = new FormData();
    formData.append('files', image);
    formData.append('ref', 'plugin::users-permissions.user');
    formData.append('refId', data?.id);
    formData.append('field', 'avatar');
    
    try {

      const uploadResponse = await axios.post('/api/upload/', formData, {
        headers: {
          'Authorization': `Bearer ${jwt}` 
        }
      });

      const uploadData = uploadResponse.data[0];

      setUserData({
        ...userData,
        avatar: {
          url: uploadData.url
        }
      })

      setImage(null);
      
    } catch (error) {

      console.error('Error:', error);
    }
  };

  const handleFileChange = (e:any) => {

    setImage(e.target.files[0]);

    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    setSelectedFiles(e.target.files);
    setImagePreviews(images);

    handleSubmitFile()
  };

  const handleClick = (event:any) => {
    hiddenFileInput?.current?.click();
  };

  useEffect(() => {

    if( !userName || !jwt || !(/[a-zA-Z]/.test(userName)) || userName?.length < 4) return;

    const query = qs.stringify({
      username: userName,
    }, {
      encodeValuesOnly: true,
    })

    api({ collection: 'users/checkFreeUsername', query, authorization: jwt }).then((data:any) => {

      setUserNameNotFree(data.available)

      if(data?.available){

        api({ method: 'PUT', collection: 'users/me', data: { username: userName }, authorization: jwt }).then((data:any) => {

          console.log(`data`)
          console.log(data)
    
        }).catch((error:any) => {
    
          if(error?.response?.status===401) setJwt(null);
        })
      }

    }).catch((error:any) => {

      console.log(`checkFreeUsername`)
      console.log(error)
    })

  }, [userName])



  useEffect(() => {

    const query = qs.stringify({
      populate: {
        username: true,
        blocked: true,
        email: true,
        phone: true,
        shop: {
          populate: '*',
        },
        avatar: {
          populate: '*',
        },
        avatar_video: {
          populate: '*',
        },
      },
    }, {
      encodeValuesOnly: true,
    })


    api({ collection: 'users/me', query, authorization: jwt }).then((data:any) => {

      if(data?.id) setData({...data});
      
      setLoading(false)

    }).catch((error:any) => {

      if(error?.response?.status===401){
        setJwt(null)
      }

      setLoading(false)
    })
  }, [])

  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
    }
  });
  
  const handleLogout = async () => {

    setData({});
    setJwt(null);
  };

  const handleOpenProducts = async () => {

  };

  const handleOpenSelectCategoryForAddProduct = async () => {

    setPage('selectCategoryForAddProduct')
  };

  return (
    <> 
      <Container maxWidth="sm" sx={{pt:/* 3 */ 'calc(25px + env(safe-area-inset-top))', pb: 6, pl: 3, pr: 3}}>
        
        <Box  sx={{ position: 'absolute', top: 20, right: 20, }}>
          <Tooltip open={true} title="Выйти" placement="right">
              <ExitToAppIcon fontSize="medium" sx={{ cursor: 'pointer',transform: 'scale(1.25)' }} onClick={handleLogout}/>
          </Tooltip>
        </Box>
        
        <Box display="flex" justifyContent="space-around" alignItems="center" flexDirection="column" sx={{ height: 'calc(100vh - 100px)', }}>

          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap:"wrap"}}>

            <input type='file' accept='image/*' onChange={handleFileChange} /* multiple */ ref={hiddenFileInput} style={{display: 'none'}}/>

            <IconButton sx={{ width: 150, height: 150 }} onClick={(event:any)=>{ handleClick(event) }}>
              <Badge badgeContent={<CloudUploadOutlinedIcon/> /* data?.avatar?.url ? 'Изменить' : 'Добавить' */}>
                <Avatar
                  alt={data?.username}
                  src={data?.avatar?.url}
                  sx={{ width: 150, height: 150 }}
                />
              </Badge>
            </IconButton>
            
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column">

              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                }) => (
                  <TextField

                    sx={{ 
                      '& .MuiOutlinedInput-input': { paddingLeft: 0, marginLeft: 0, ...( !userNameNotFree ? { color: 'red' }: {}) }
                    }}
                    
                    size="small"
                    margin="normal"
                    variant="outlined"

                    onClick={()=>{window?.navigator?.vibrate && window?.navigator?.vibrate(10);}}
                    autoFocus={false}

                    label="Пользователь"
                    //placeholder="Имя"

                    value={userName || userName?.length === 0 ? `${userName}` : ( data?.username ? `${data?.username}` : '' ) }

                    onBlur={onBlur}
                    inputRef={(input) => {

                      ref(input);
                      //if (input) searchFormTextFieldRef.current = input;
                    }}

                    onChange={(e:any)=>{

                      setUserName(e.target.value)
                    }}
                    onFocus={(e:any)=>{

                      //setOpen(!open)
                    }}
                    InputProps={{
                      startAdornment: (
                        
                        <InputAdornment position="start" sx={{ marginLeft: 1, mr: 0 }}>

                            {userName || userName?.length === 0 || (/[a-zA-Z]/.test(data?.username)) ? '@' : '+'}
                        </InputAdornment>
                      ),
                      /* endAdornment: (
                        
                        <InputAdornment position="end" sx={{ marginRight: 1 }}>
            
                          <IconButton onClick={()=>{
                            //setSearch('');
                            }} edge="end">
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ), */
                    }}
                  />
                )}
                name="name"
                control={control}
                rules={{ required: true }}
              />

              <Typography variant="body2" sx={{ color: 'red', marginTop: 1 }}>

                {!userNameNotFree && 'Занято'}

              </Typography>

            </Box>

          </Box>
          
          {data?.shop && 
          
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" sx={{ /* width: 250, border: '1px solid grey', borderRadius: 5, p: 2,  */'& > *': { m: '5px !important' },  }}>

              <Typography variant="subtitle1">

                {/* Функции магазина */} {data?.shop?.name}

              </Typography>

              <Button variant="contained" onClick={()=>{

                setCatalogShopProductEdit(true);
                setPage('catalog');
              }} sx={{ width: 180, }}>Список товаров</Button>

              <Button variant="contained" onClick={()=>{
                
                handleOpenSelectCategoryForAddProduct()
                setProductEdit(null)

              }} sx={{ width: 180, }}>Добавить товар</Button>

            </Box>
          }

          {/* <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column" sx={{ '& > *': { m: '5px !important' }, }}>

            <Button variant="contained" onClick={()=>{resetProductSort()}} sx={{ width: 180, }}>Сброс сортировки</Button>

            <Button variant="contained" onClick={()=>{ setData({}); setJwt(null); }} sx={{ width: 180, }}> Выйди </Button>

          </Box>
          */}

        </Box>
        
      </Container>

      {/* <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={shareLot||false}
        autoHideDuration={2500}
        onClose={()=>{setShareLot(!shareLot)}}
        message="Созранен"
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 2,
            zIndex: 10000,
          },
          '& .MuiSnackbarContent-message ': {
            display: 'block',
            textAlign: "center",
            width: '100%',
          },
        }}
      /> */}

    </>
  )
}

export default Account
