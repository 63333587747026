import { atom, selector } from "recoil"

export const categoryCatalogStateAtom = atom({
  key: "categoryCatalogStateAtom",
  default: [],
})

export const categoryCatalogStateSelector = selector({
  key: "categoryCatalogStateSelector",
  get: ({ get }) => {
    const categoryCatalog = get(categoryCatalogStateAtom)
    return categoryCatalog
  }
})

