
import { FC, useEffect, useState, useRef } from 'react'

import qs from 'qs'

import { useSetRecoilState } from 'recoil'
import { shopStateAtom } from '@state/shop'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import AdsSlider from '@components/AdsSlider'

import Api from '@services/api'

const Premiums: FC<any> = () => {

  const [data, setData] = useState<any[]>([])

  const setShop = useSetRecoilState(shopStateAtom)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
  
    const query = qs.stringify({
      randomSort: false,
      filters: {
        active: true,
      },
      populate: {
        shop: {
          fields: ["name", "description", "coords"],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
      },
      pagination: {
        pageSize: 500,
        page: 1,
      },
      sort: "createdAt:DESC",
      locale: ['ru'],
    }, {
      encodeValuesOnly: true,
    })

    Api({ collection: 'premuim-shops', query }).then((data:any) => {

      if(data?.data){

        setData([...data?.data])
      }
      
      setLoading(false)

    }).catch((error:any) => {

      setLoading(false)
    })

  }, [])

  return (
    <>
      <Container maxWidth="sm" sx={{pt:/* 3 */ 'calc(25px + env(safe-area-inset-top))', pb: 5, pl: 3, pr: 3}}>

        <Box sx={{ mb: 5 }}>

          <AdsSlider/>

        </Box>
        
        {data?.map((item:any, index:number) => {

          return (
            <Box key={index} sx={{ m: 2, mb: 3.5 }} onClick={() => {
              setShop(item?.shop)
            }}>

              <Stack direction="row" spacing={2}>
                
                <Avatar alt="Cindy Baker" src={item?.shop?.icon.url} />

                <Typography variant="h6" gutterBottom>

                  {item?.shop?.name}

                </Typography>

              </Stack>

            </Box>
          )
        })}
      </Container>
    </>
  )
}

export default Premiums
