import { FC } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'

import { globalProgressBackdropStateAtom } from '@state/globalProgressBackdrop'
import { globalProgressBackdropTextStateAtom } from '@state/globalProgressBackdropText'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const GlobalProgressBackdrop: FC<any> = () => {
  
  const [status, setStatus] = useRecoilState<boolean>(globalProgressBackdropStateAtom)
  const text = useRecoilValue<string|null>(globalProgressBackdropTextStateAtom)

  const handleClose = () => {
    setStatus(false);
  };

  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
    open={status}
    onClick={handleClose}
    >

      <CircularProgress color="inherit" />

      { text &&

        <Typography variant="h6" component="div" sx={{ mt: 3, width: '100%', textAlign: 'center' }}>
          {text}
        </Typography>
      }
      
    </Backdrop>
  )
}

export default GlobalProgressBackdrop
