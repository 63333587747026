import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { YMaps } from "react-yandex-maps"

import {
  RecoilRoot,
} from 'recoil';

import reportWebVitals from './reportWebVitals';

import {
  YMap,
  YMapComponentsProvider,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapDefaultMarker,
  // ...other components
} from "ymap3-components";
/* 
  // @ts-expect-error
const lockOrientation = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;
lockOrientation.lock('portrait');
 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const YANDEX_MAP_API_KEY='68961452-eced-4857-9135-b8b9a9333c4e'

root.render(
    <RecoilRoot>
      <YMaps query={{ apikey: YANDEX_MAP_API_KEY }} >
        <App />

      </YMaps>
{/* 
      <YMapComponentsProvider apiKey={YANDEX_MAP_API_KEY}>
      <YMap 
                key="map"
                //ref={(ymap: YMaps.YMap) => setYmap(ymap)}
                location={{ center: [37.95, 55.65], zoom: 10 }}
                mode="vector"
                theme="dark"
      
      >
        <YMapDefaultSchemeLayer />
        <YMapDefaultFeaturesLayer />
        <YMapDefaultMarker
          coordinates={[37.8, 55.8]}
        />
      </YMap>
    </YMapComponentsProvider>

 */}
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
