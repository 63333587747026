import { atom, selector } from "recoil"

export const categoryStateAtom = atom({
  key: "categoryStateAtom",
  default: null,
})

export const categoryStateSelector = selector({
  key: "categoryStateSelector",
  get: ({ get }) => {
    const category = get(categoryStateAtom)
    return category
  }
})

