import { atom, selector } from "recoil"

export const mallStateAtom = atom({
  key: "mallStateAtom",
  default: null,
})

export const mallStateSelector = selector({
  key: "mallStateSelector",
  get: ({ get }) => {
    const mall = get(mallStateAtom)
    return mall
  }
})

