
import { FC, useEffect, useState, useRef } from 'react'

import qs from 'qs'

import { useSetRecoilState } from 'recoil'

import { shopStateAtom } from '@state/shop'
import { mallStateAtom } from '@state/mall'

import { categoriesStateAtom } from '@state/categories'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import NearMeIcon from '@mui/icons-material/NearMe';

import styles from './styles.module.scss'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import Api from '@services/api'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { YMaps, Map, Placemark, GeolocationControl, TrafficControl, TypeSelector, ZoomControl } from "react-yandex-maps"

const location = window.location.hostname === 'localhost' ? `http://localhost:15187` : window.location.origin 

const development = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'

const iconSize:number = 60;

const iconSizeZoomSize: number = 16;

type Coordinate = {
  lat: number;
  lon: number;
};

function getDistanceBetweenTwoPoints(cord1: Coordinate, cord2: Coordinate) {
  if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
    return 0;
  }

  const radlat1 = (Math.PI * cord1.lat) / 180;
  const radlat2 = (Math.PI * cord2.lat) / 180;

  const theta = cord1.lon - cord2.lon;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344; //convert miles to km
  
  return dist;
}

interface IPoints {
  id: number
  type?: string // Офис, Магазин, Склад
  name: string
  address: string

  description: string
  coords: {
    lat: number
    lng: number
  },
  icon?: string
  zoom: number
}

let yandexMapPoints:IPoints[] = [
  {
    id: 0,
    type: "луговая 91",// Офис, Магазин, Склад
    name: "GENiKiNEG", 
    description: "г. Владивосток, ул. луговая 91 <br> Встречи только по предварительной договоренности",
    address:'',

    //coords: [43.131357, 131.942418],
    coords: {
      lat:	43.131357,
      lng:	131.942418
    },
    zoom: 16
  },
  {
    id: 1,
    type: "Светланская 108Д",// Офис, Магазин, Склад
    name: "GENiKiNEG",
    description: "Светланская 108Д <br> Встречи только по предварительной договоренности",
    address:'',

    //coords: [43.11196, 131.92332],
    coords: {
      lat:	43.11196,
      lng:	131.92332
    },
    zoom: 16
  } ,
  {
    id: 2,
    type: "Семёновская 1",// Офис, Магазин, Склад
    name: "GENiKiNEG",
    description: "Семёновская 1 <br> Встречи только по предварительной договоренности",
    address:'',
    //coords: [43.119326, 131.880607],
    coords: {
      lat:	43.119326,
      lng:	131.880607
    },
    zoom: 16
  } 
]

const Maps: FC<any> = () => {

  const [loading, setLoading] = useState<boolean>(false)

  const [data, setData] = useState<any[]>([])

  const [zoom, setZoom] = useState<number>(16)

  const setShop = useSetRecoilState(shopStateAtom)
  const setMall = useSetRecoilState(mallStateAtom)
  
  const setCategories = useSetRecoilState<any>(categoriesStateAtom)

  const query = qs.stringify({
    randomSort: false,
    filters: {
      parent: {
        name: {
          $null: true
        }
      },
    },
    populate: {
      parent: {
        populate: {
          fields: ['id', 'name']
        } 
      },
      icon: {
        fields: ['url']
      },
      photo: {
        fields: ['url']
      },
      background_color: true,
      childrens: {
        populate: {
          parent: {
            populate: {
              fields: ['id', 'name']
            },
            childrens: {
              populate: {
                parent: {
                  populate: {
                    fields: ['id', 'name']
                  },
                  fields: ['id', 'name']
                },
              },
            },
            fields: ['id', 'name']
          },
          childrens: {
            populate: {
              parent: {
                populate: {
                  fields: ['id', 'name']
                },
                childrens: {
                  populate: {
                    parent: {
                      populate: {
                        fields: ['id', 'name']
                      },
                      childrens: {
                        populate: {
                          fields: ['id', 'name']
                        },
                      },
                      fields: ['id', 'name']
                    },
                  },
                },
                fields: ['id', 'name']
              },
              childrens: {
                populate: {
                  fields: ['id', 'name']
                },
                childrens: {
                  populate: {
                    parent: {
                      populate: {
                        fields: ['id', 'name']
                      },
                      childrens: {
                        populate: {
                          fields: ['id', 'name']
                        },
                      },
                      fields: ['id', 'name']
                    },
                  },
                },
                fields: ['id', 'name']
              },
            },
          },
        },
      },
    },
    locale: ['ru'],
  }, {
    encodeValuesOnly: true, // prettify URL
  })

  Api({ collection: 'categories', query }).then((data:any) => {

    setCategories(data?.data)

  }).catch((error:any) => {
    
    console.log(error)
  })


  useEffect(() => {
  
    const query = qs.stringify({
      randomSort: false,
      filters: {
        show_on_map: true,
        show_on_map_before: {
          $gte: new Date().getTime()
        },
      },
      populate: {
        malls: {
          fields: ["name", "coords"],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
        icon: {
          fields: ["url"],
        }
      },
      pagination: {
        pageSize: 500,
        page: 1,
      },
      sort: "createdAt:DESC",
      locale: ['ru'],
      group: true,
    }, {
      encodeValuesOnly: true,
    })

    Api({ collection: 'shops', query }).then((data:any) => {

      if(data?.data){

        let updateMalls:any = []
        let updateShops:any = []

        for(const item of data.data){

          if(!updateShops.find((x:any) => x.id === item.id) && item.malls.length === 0){
            
            updateShops.push({
              id: item.id,
              type: 'shop',
              name: item.name,
              description: item.description,
              address: item.address,
              coords: item.coords,
              icon: item.icon,
              zoom: 16
            })

          }
          
          for(const mail of item.malls){

            if(!updateMalls.find((x:any) => x.id === mail.id)){

              updateMalls.push({...mail, type: 'mall'})
            }
          }
        }

        setData([...updateMalls, ...updateShops])
      }
      
      setLoading(false)

    }).catch((error:any) => {

      console.log(`data `)
      console.log(error)

      setLoading(false)
    })

  }, [])


  //let yandexMapPoints:any = []
/*   
  if(data?.data?.data){
    data?.data?.data.map((item:any) => {

      yandexMapPoints.push({
        id: item.id,
        type: item.attributes.type,
        name: item.attributes.name,
        description: item.attributes.description,
        address: item.attributes.address,
        
        coords: [item.attributes.map.latitude, item.attributes.map.longitude],
        zoom: item.attributes.map.zoom
      })
    })
    
  }
   */
  const map = useRef<any>(null)

  useEffect(() => {

    console.log('map?.current?.getZoom')
    console.log(map?.current?.getZoom())

  }, [])
  
  const [open, setOpen] = useState<number>(0)

  useEffect(() => {
    AOS.init()
  }, [])

  let start = {
    id: -1,
    name: "Genikineg",
    description: " +7 (950) 29-133-51",
    address: "луговая 91",/* 
    coords: [43.131357, 131.942418], */
    coords: {
      lat:	43.115976,
      lng:	131.942418
    },
    zoom: 12.5
  }
{/*         43.115976, 131.893253
        43.119560, 131.906127 */}
  if(yandexMapPoints.length > 0){
      start = {
        id: yandexMapPoints[0]?.id,
        name: yandexMapPoints[0]?.name,
        description: yandexMapPoints[0]?.description,
        address: yandexMapPoints[0]?.address,
        coords: {
          lat: yandexMapPoints[0]?.coords.lat,
          lng: yandexMapPoints[0]?.coords.lng
        },
        zoom: start.zoom || 16 /* data[0].map.zoom */
      }
  }

  const lastPoint = useRef<any>(start.coords)

  const [selectCoords, setSelectCoords] = useState<any>({
    center: [start.coords.lat, start.coords.lng],
    zoom: start.zoom
  })
  
  const onItemClick = (id: number, coords:any, zoom:number) => {

    setOpen(id)

    const distance = getDistanceBetweenTwoPoints({
      lat: coords[0],
      lon: coords[1]
    },{
      lat: lastPoint.current[0],
      lon: lastPoint.current[1]
    })

    if(distance < 0.1){return}

    lastPoint.current = coords

    map.current.options.set('maxAnimationZoomDifference', Infinity);

     // 3 km
    if(distance < 3){

      map.current.setCenter(coords, zoom, {duration: 800}).then(() => {})

    }else{

      map.current.setZoom(8, {duration: 350}).then(() => {

        setSelectCoords( { center: coords} )
  
        setTimeout(()=>{
  
          map.current.options.set('maxAnimationZoomDifference', Infinity)
          map.current.setZoom(zoom, {duration: 350}).then(() => {})
          
        }, 100)
  
      })

    }

  }

  const instanceRef = (mapObject: any) => {
    if (mapObject) {
      map.current = mapObject
      map.current.events.add('boundschange', function (event:any) {
        if (event.get('newZoom') != event.get('oldZoom')) {
            //alert('Zoom level changed');
        }
    });
    }
  }

  const mapOnChange = (event:any) => {
    const oldStateMap = {
      center: event.originalEvent.oldCenter,
      zoom: event.originalEvent.oldZoom,
      bounds: event.originalEvent.oldBounds,
    }
    const newStateMap = {
      center: event.originalEvent.newCenter,
      zoom: event.originalEvent.newZoom,
      bounds: event.originalEvent.newBounds,
    }

    setZoom(newStateMap.zoom)
    console.log({ oldStateMap, newStateMap })
  }
  
  const openMarker = (point: any) => {
    alert(`id: ${point.id}, type:${point.type}, name: ${point.name}`);
  }

  const buttons = [

    <Button key="plus-zoom" sx={{backgroundColor: 'white', }} size='small'
    onClick={()=>{

      if(map.current.getZoom() > 25) return;
      map.current.setZoom(map.current.getZoom()+1, {duration: 350}).then(() => {})
    }}>
      <AddIcon fontSize='small' sx={{color: 'black'}}/>
    </Button>,

    <Button key="minus-zoom" sx={{backgroundColor: 'white'}} size='small'
    onClick={()=>{

      if(map.current.getZoom() <= 1) return;
      map.current.setZoom(map.current.getZoom()-1, {duration: 350}).then(() => {})
    }}>
      <RemoveIcon fontSize='small' sx={{color: 'black'}}/>
    </Button>,

  ];

  return (
    <>
    <div id='map' className={styles.section} style={{width: '100vw', height: '100vh'}} >
      {/* <div className={styles.tabsPoint}>
        <div className={styles.tabsPointBox}>

          {yandexMapPoints.map((item:any) => (
            <div key={item.id} onClick={() => onItemClick(item.id, item.coords, item.zoom)} className={open === item.id ? styles.tabsPointBoxItemOpen:styles.tabsPointBoxItem}>
              <div className={styles.tabsPointBoxItemTitle}>
              {item.type} {item.name}
                <ArrowDownwardIcon className={styles.icon} style={{transform: open === item.id ? 'rotate(-180deg)':'rotate(0deg)'}} fontSize='small'/>
              </div>
              <AnimateHeight
                duration={500}
                height={open === item.id ? 'auto':0}
              >
                <div className={styles.tabsPointBoxItemDescription} dangerouslySetInnerHTML={{ __html: item.description }}></div>
              </AnimateHeight>
            </div>
          ))}

        </div>
      </div> */}
      <div className={styles.canvas}>

        <YMaps>

          <Map
          instanceRef={ref => instanceRef(ref)}
          state={selectCoords}
          className={development=== 'dev' ? styles.mapContent:''}
          style={{width: '100vw', height: '100vh'}}
          //defaultState={{ center: [43.115976, 131.693253], zoom: 25 }}
          modules={["templateLayoutFactory", "layout.ImageWithContent"]}
          controls={[]}
          onLoad={(maps: any): void => {}}
          onBoundsChange={mapOnChange} // любое изменение границ карты (как при зуме, так и при перетаскивании)
          >

            {/* <GeolocationControl options={{

              position: {
                top: 215,
                right: 10,
              }
            }} />

            <ZoomControl options={{
              size: "small",
              
              position: {
                top: 250,
                right: 10,
              }
            }} /> */}



            {/* <TrafficControl options={{
              float: 'right'
            }} />
            <TypeSelector options={{
              float: 'right'
            }} /> */}

            {/* <Placemark geometry={[43.119684, 131.927359]} /> */}

            {data.map((point:any, index:number) => (

              <Placemark
              key={index}
              geometry={[point.coords?.lat, point.coords?.lng]}

              instanceRef={(ref: any) => {
                ref?.events.remove(['click']);
                ref?.events.add('click', function (e: any) {

                  console.log(point.name);

                  if(point.type === 'shop') return setShop(point);

                  return setMall(point);
                })
              }}

              modules={["geoObject.addon.balloon"]}
              /* properties={{
                preset: 'islands#icon',
                iconColor: '#3caa3c',
                balloonContent: `<div class="baloon-content">${point.name}<br>${point.description}</div>`
              }} */
              options={{
                iconLayout: "default#image",
                iconImageSize: [iconSize*(zoom/iconSizeZoomSize), iconSize*(zoom/iconSizeZoomSize)],
                iconImageHref: `${location}${point.icon.url}`,
                iconImageOffset: [-((iconSize*(zoom/iconSizeZoomSize))/2), -((iconSize*(zoom/iconSizeZoomSize))/2)],
              }}
              />

            ))}
          </Map>

        </YMaps>

      </div>
    </div>

    <Box
      sx={{
        position: 'fixed',

        top: '50%',
        transform: 'translateY(-50%)',

        right: 5,

        display: 'flex',
        '& > *': {
          m: 1,
        },
      }}
    >

      <ButtonGroup
        orientation="vertical"
        aria-label="Vertical button group"
        variant="contained"
        sx={{
          backgroundColor: 'white',
          borderRadius: 3.5,
          width: 45,
        }}
      >
        {buttons}
      </ButtonGroup>
    </Box>
    </>
  )
}

export default Maps
