
import './App.css'

import WebFont from 'webfontloader'

import { useMemo, useRef, useEffect, useState } from 'react'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { size } from 'lodash'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { pageStateAtom } from '@state/page'
import { jwtStateAtom } from '@state/jwt'


import PopupCallBack from '@components/PopupCallBack'
import Start from '@components/Start'

import Logo from '@components/Logo'
import Navigation from '@components/Navigation'

import Search from '@components/Search';
import Map from '@components/Map'

import Auth from '@components/Auth'
import Account from '@components/Account'

import Catalog from '@components/Catalog'
import Premiums from '@components/Premiums'
import Basket from '@components/Basket'

import Shop from '@components/Shop'
import Mall from '@components/Mall';

import SelectCategoryForAddProduct from '@components/SelectCategoryForAddProduct';
import AddProduct from '@components/AddProduct';

import Policy from '@components/Policy'

import ProductSort from '@components/ProductSort'

import WelcomeApp from '@components/WelcomeApp'

import GlobalProgressBackdrop from '@components/GlobalProgressBackdrop'

import useBotDetect from '@hooks/useBotDetect'
import useMobileDetect from '@hooks/useMobileDetect'
import usePlatformDetect from '@hooks/usePlatformDetect'

import useThemeMode from '@hooks/useThemeMode'

import parseDynamicComponent from '@utils/parseDynamicComponent'

import grey from '@mui/material/colors/grey'

import { useFullscreen } from 'ahooks';

const borderRadius = 5

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 980,
      lg: 1240,
      xl: 1626,
    },
  },
  palette: {
    primary: grey,
    secondary: grey,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: 24,
          userSelect: 'none',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: borderRadius,
          borderColor: 'none',
          border: 0, 
          '& .MuiOutlinedInput-root': {
            borderRadius: borderRadius,
            height: 50,
            border: '0px solid #909090',
            padding: 5,

            ':hover': {
              border: '0px solid #fd0000 !important',
              //boxShadow: '-1px 1px 4px 4px rgba(219, 219, 219, 0.25);',
              borderRadius: borderRadius,
              borderColor: 'none',
            },
            ':focus-within': { border: '0px solid #fd0000 !important' }
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            ':hover': {
              border: '0px solid #909090 !important',
              boxShadow: 'none'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiOutlinedInput-input': {
            padding: 10,
          }
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: 'unset',
          },

        }
      }
    },
    MuiPaper:{
      styleOverrides: {
        root: {
          boxShadow: "unset",
          transition: 'unset',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          //backgroundColor: "rgb(52, 52, 52)",

        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            height: 'unset',
          },
        },
        paper: {
          //backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: borderRadius,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // Добавление тени к выпадающему списку
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#e3abed',
          },
          '&': {
            //backgroundColor: '#fff',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          background: 'unset',
          border: 0,
          ':hover': {
            background: 'unset',

          },
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          background: 'unset',

          height: 50,
          border: 0,
          ':hover': {

          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root:{
          userSelect: 'none',
        }
      }
    },
    MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#000',

            background: '#F3F3F3',
            //background: 'rgba(255, 255, 255, 0.1)',

            borderWidth: '26px',
            borderRadius: '25px',
            borderColor: 'none',
            border: 0,

            ':hover': {
              borderRadius: '25px',
              borderColor: 'none',
              border: 0,
            },

            '& .MuiOutlinedInput-root': {
              borderRadius: '7px',
              height: 50,
              border: '1px solid #909090',
              
              ':hover': {
                border: '0px solid #fd0000 !important',
                boxShadow: '-1px 1px 4px 4px #FFEAEA'
              },
              ':focus-within': { border: '0px solid #fd0000 !important' }
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              ':hover': {
                border: '0px solid #909090 !important',
                boxShadow: 'none',
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
        }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          padding: 15,
          border: 0,
          //backgroundColor: 'unset',
          backgroundColor: 'rgb(241, 241, 241)',
          ':hover': {
            /* opacity : 0.7, */
            border: 0,
            background: 'unset',
            backgroundColor: 'rgb(241, 241, 241)',
          },
          color: 'rgb(98, 100, 101)', '&:hover': {color: 'rgb(98, 100, 101)'}, cursor: 'pointer'
        }
      }
    },
  },
  transitions: {
    duration: {
        enteringScreen: 1000, // 1000,
        leavingScreen: 100, // 350,
    }
  }
})

const mode:string = 'light'

const light = {
  text:  'rgb(98, 100, 101)',
  background:  'rgb(255, 255, 255)',
  buttonBackgroundColor:  'rgb(32, 33, 36)',
  buttonColor:  'rgb(32, 33, 36)',
}

const dark = {
  text: 'rgb(188, 192, 195)',
  background: 'rgb(32, 33, 36)',
  buttonBackgroundColor: 'rgb(32, 33, 36)',
  buttonColor: 'rgb(32, 33, 36)',
}

/* 
window.onload = () => {

  setTimeout(function(){
    //alert('Ee 2');
    //navigator?.splashscreen?.hide();
      
        //alert(navigator.splashscreen.hide());
        
        // @ts-expect-error
        alert(device.cordova);
    //
    //document.body.style.backgroundColor = 'red'; // или любой другой цвет фона
    //(navigator as Navigator & { splashscreen?: any }).splashscreen?.hide();
  }, 500);
}; */






const App = () => {

  const themeMode = useThemeMode()

  const [page, setPage] = useRecoilState(pageStateAtom)

  const jwt = useRecoilValue(jwtStateAtom)

  useEffect(() => {
/* 

  useEffect(() => {

    if(!menu) return;
    const random = Math.random().toString(36)

    window.history.pushState({ menu: random }, "menu", "?menu="+random);

  }, [menu])
  
*/
    const onPop = () => {
      setPage('start')
    }

    window.addEventListener("popstate", onPop);

    return () => {

      window.removeEventListener("popstate", onPop);
    };
  }, []);






  const [isFullscreen, setIsFullscreen] = useState(false);

  const requestFullscreen = () => {
    
    //if(!isFullscreen) return document.body.requestFullscreen();
  };

  // Watch for fullscreenchange
  useEffect(() => {
    
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }
          
    document.addEventListener('fullscreenchange', onFullscreenChange);
  
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  useEffect(() => {
    
    document.addEventListener('click', requestFullscreen);
    document.addEventListener('touchstart', requestFullscreen);
  
    return () => {
      document.removeEventListener('click', requestFullscreen);
      document.removeEventListener('touchstart', requestFullscreen);
    };
  }, []);


  



  /* useEffect(() => {

    const onLoadForCordova = () => {
      setTimeout(function(){
        alert('Ee');
        (navigator as Navigator & { splashscreen?: any }).splashscreen?.hide();
      },555)
    };
    
    document.addEventListener('onload', onLoadForCordova);
  
    return () => {
      document.removeEventListener('onload', onLoadForCordova);
    };
  }, []); */






  /* // User has switched back to the tab
  const onFocus = () => {
    console.log("Tab is in focus");

    return document.body.requestFullscreen();
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    console.log("Tab is blurred");
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
        window.removeEventListener("focus", onFocus);
        window.removeEventListener("blur", onBlur);
    };
  }, []); */
  
  return  <>
          <GoogleReCaptchaProvider 
          reCaptchaKey=''
          container={{
            parameters: {
              badge: 'bottomright', // optional, default undefined
              theme: 'dark', // optional, default undefined
            }
          }}>
            <ThemeProvider
            theme={{
              ...theme, 
              components: { 
                ...theme.components, 

                MuiInputBase: {
                  styleOverrides: {
                    root: {
                      color: mode === 'dark' ? dark.text : light.text,
                      background: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(243, 243, 243, 1)',
                    }
                  }
                },
                MuiButtonGroup: {
                  styleOverrides: {
                    root: {
                      color: mode !== 'dark' ? dark.buttonColor : light.buttonColor,
                    }
                  }
                },
                MuiBackdrop: {
                  styleOverrides: {
                    root: {
                      backgroundColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.5)' ,
                    }
                  }
                },
              },
              palette: {
                ...theme.palette,
                               
                divider: mode === 'dark' ? dark.background : light.background,

                background: {
                  default: mode === 'dark' ? dark.background : light.background,
                  paper: mode === 'dark' ? dark.background : light.background,
                },
                text: {
                  primary: mode === 'dark' ? dark.text : light.text,
                  secondary: mode === 'dark' ? dark.text : light.text,
                },
                primary: {
                  main: mode === 'dark' ? dark.buttonColor : light.buttonColor,
                  company: mode === 'dark' ? dark.buttonBackgroundColor : light.buttonBackgroundColor,
                },

                action: {
                  ...theme?.palette?.action, 
                },
              }
            }}>
              
              <CssBaseline />

              { page === 'WelcomeApp' && <>
              
              <Logo/>

              <WelcomeApp/>
              
              </> }

              { page === 'start' && <Start/> }

              { page !== 'start' && 
                
                <Box sx={{display: page === 'map' ? 'block' : 'none'}}>

                  <Logo/>

                  <Map/>
                  
                  <Navigation/>

                  <Search/>

                </Box>
              }


              { page === 'selectCategoryForAddProduct' && 

                <>

                  <SelectCategoryForAddProduct/>

                  <Navigation/>

                </>
              }


              { page === 'addProduct' && 

                <>

                  <AddProduct/>

                  <Navigation/>

                </>
              }
              

              { page === 'account' && 

                <>

                  { jwt ? 
                  
                  <>

                    {/* <Logo/> */}

                    <Account/>

                    <Navigation/>

                  </>
                  :
                  <>

                    {/* <Logo/> */}

                    <Auth/>

                    <Navigation/>
                  </>
                  }

                </>

               }

              { page === 'premiums' && 
                
                <>

                  {/* <Logo/> */}
                  
                  <Premiums/>

                  <Navigation/>
                </>
              }

              { page === 'basket' && 
                
                <>

                  {/* <Logo/> */}
                  
                  <Basket/>

                  <Navigation/>
                </>
              }


              { page === 'catalog' && 
                
                <>
                  
                  <Catalog/>

                  <Navigation/>
                </>
              }
             
             
              {/* <Search/> */}


              <Shop/>

              <Mall/>

              <Policy/>

              <ProductSort/>

              {/* <Auth/> */}

              <GlobalProgressBackdrop/>

              </ThemeProvider>

              </GoogleReCaptchaProvider>

            </>
  
}

export default App
