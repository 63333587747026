import axios, { AxiosResponse } from 'axios';

const dev = true;

const location = window.location.hostname === 'localhost' ? `http://localhost:15187` : window.location.origin 

declare global {
  interface Window {
    userIp: string;
  }
}

const lot = ({ method = 'GET', id=null, collection=null, query, data=null, authorization=null }: any): Promise<any> => {
  return new Promise(async (resolve: (value: any) => void, reject: (reason?: any) => void) => {

    let url 
    
    if(dev) url =  id ? `${location}/api/${collection}/${id}` : `${location}/api/${collection}`;
    if(dev) url = query ? `${url}?${query}` : url;

    axios({
      method,
      url,
      headers: {
        accept: 'application/json',
        'cache-control': 'no-cache',
        'Content-Type':/*  method===`POST` ? 'application/x-www-form-urlencoded' : */ 'application/json',
        ...(authorization && { Authorization: `Bearer ${authorization}` }),
      },
      data: data,
    })
      .then((response: AxiosResponse) => {
        
        if (response.status === 200) {

          if(response?.data){

            if(dev)return resolve(response.data);

            return resolve(data);
          }
          return reject({});
        }
        
        else 
        if (response.status === 401) {

          console.log('bad token');

          return reject(`bad token`);
        }
        
        else {

          return reject(`Request failed with status ${response.status}`);
        }
      })
      .catch((error: any) => {
        
        console.error(error);
        return reject(error);
      });
  });
};

export default lot;
export { lot };
