import { atom, selector } from "recoil"

export const authOpenStateAtom = atom({
  key: "authOpenStateAtom",
  default: false,
})

export const authOpenStateSelector = selector({
  key: "authOpenStateSelector",
  get: ({ get }) => {
    const authOpen = get(authOpenStateAtom)
    return authOpen
  }
})

