
import { FC } from 'react'

import qs from 'qs'

import { useRecoilState } from 'recoil'

import { policyStateAtom } from '@state/policy'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'

import DesktopOrMobileOrBot from '@components/DesktopOrMobileOrBot'

import Api from '@services/api'

const Policy: FC<any> = () => {

  const [open, setOpen] = useRecoilState(policyStateAtom)

  /* const [data, setData] = useState<any[]>([])

  const setShop = useSetRecoilState(shopStateAtom)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
  
    const query = qs.stringify({
      randomSort: false,
      filters: {
        active: true,
      },
      populate: {
        shop: {
          fields: ["name", "coords"],
          populate: {
            icon: {
              fields: ["url"],
            }
          }
        },
      },
      pagination: {
        pageSize: 500,
        page: 1,
      },
      sort: "createdAt:DESC",
      locale: ['ru'],
    }, {
      encodeValuesOnly: true,
    })

    Api({ collection: 'premuim-shops', query }).then((data:any) => {

      if(data?.data){

        console.log(`data?.data`)
        console.log(data?.data)

        setData([...data?.data])
      }
      
      setLoading(false)

    }).catch((error:any) => {

      console.log(`data `)
      console.log(error)

      setLoading(false)
    })

  }, []) */

  const onClose = () => setOpen(false);

  return (
    <>
      <DesktopOrMobileOrBot open={open || false} onClose={onClose}> 

        <Container maxWidth="sm" sx={{  pt: 0, mt: 0, pb: 5, pl: 3, pr: 3, height: '90vh', overflow: 'auto'}}>

          <Typography variant="h6" gutterBottom  component="div" style={{ whiteSpace: 'pre-line' }} sx={{ pt: 0, mt: 0}}>
{`
Политика конфиденциальности

1. Общие положения

1.1. Настоящая Политика конфиденциальности (далее — Политика) регулирует порядок обработки и защиты персональных данных пользователей (далее — Пользователи), используемых в мобильном приложении [Название приложения] (далее — Приложение).

1.2. Использование Приложения означает безоговорочное согласие Пользователя с настоящей Политикой и условиями обработки его персональных данных.

2. Персональные данные

2.1. Под персональными данными понимается любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).

2.2. Персональные данные, которые могут быть собраны и обработаны:

    Имя;
    Электронная почта;
    Номер телефона;
    Данные о местоположении;
    Идентификаторы устройств;
    Информация о действиях в Приложении.

3. Цели обработки персональных данных

3.1. Персональные данные Пользователей обрабатываются для следующих целей:

    Предоставление услуг и функций Приложения;
    Улучшение качества работы Приложения;
    Техническая поддержка и консультации;
    Проведение аналитики и исследований;
    Рассылка уведомлений и рекламных материалов (с согласия Пользователя).

4. Обработка и защита персональных данных

4.1. Обработка персональных данных осуществляется на законной и справедливой основе.

4.2. Обработка персональных данных может включать сбор, запись, систематизацию, накопление, хранение, уточнение, использование, передачу, блокирование и уничтожение данных.

4.3. Приложение предпринимает необходимые организационные и технические меры для защиты персональных данных от несанкционированного доступа, утраты, изменения и распространения.

5. Передача персональных данных третьим лицам

5.1. Персональные данные Пользователей могут передаваться третьим лицам в следующих случаях:

    С согласия Пользователя;
    Для выполнения обязательств перед Пользователем;
    В случаях, предусмотренных законодательством.

6. Права Пользователей

6.1. Пользователи имеют право на доступ к своим персональным данным, их исправление, обновление и удаление.

6.2. Для реализации своих прав Пользователь может обратиться к разработчику Приложения через контактные данные, указанные в разделе 8.

7. Изменения в Политике конфиденциальности

7.1. Разработчик Приложения оставляет за собой право изменять настоящую Политику в любое время. Изменения вступают в силу с момента их опубликования в Приложении.

8. Контактная информация

8.1. По всем вопросам, связанным с настоящей Политикой и обработкой персональных данных, Пользователь может связаться с разработчиком Приложения по следующему адресу электронной почты: [Электронная почта].

9. Согласие на обработку персональных данных

9.1. Пользователь, принимая условия настоящей Политики, дает согласие на обработку своих персональных данных в соответствии с изложенными выше условиями.
`}
          </Typography>


          <LoadingButton
          fullWidth
          sx={{
            mt: 1,
            bgcolor: 'primary.company',
            "&:hover": {
              bgcolor: 'primary.company',
              opacity: 0.7,
            },
            "&:active": {
              bgcolor: 'primary.company',
              opacity: 0.7,
            },
            '& .MuiButton-endIcon': {
              marginTop: -0.5,
            }
          }}
          loading={false} variant="outlined" onClick={() => setOpen(false)}>Продолжить</LoadingButton>
          

        </Container>

      </DesktopOrMobileOrBot>
    </>
  )
}

export default Policy
